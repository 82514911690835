<template>
  <template v-for="item in alerts" :key="item.timeRanges">
    <div :class="typeClass(item.type)" role="alert">
      <font-awesome-icon :icon="typeIcon(item.type)" />
      {{ item.message }}
      <button
        v-if="!item.autoHide"
        @click="removeAlert(item.timeRange)"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button>
    </div>
  </template>
</template>

<script setup>
import { computed, watch, defineProps, onMounted, onUnmounted, ref } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faExclamationCircle,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const props = defineProps({
  data: {
    type: Object,
  },
});

const alerts = ref([]);
const timing = 3000;
let timerId = null;

const removeAlert = (timeRange) => {

  for (let i = alerts.value.length - 1; i >= 0; i--) {
    if (alerts.value[i].timeRange === timeRange) {
      alerts.value.splice(i, 1);
    }
  }
};

const typeIcon = computed(() => {
  return (type) => {
    switch (type) {
      case 1:
        return faExclamationCircle;
      case 2:
        return faCheckCircle;
      case 3:
      case 4:
        return faExclamationCircle;
      case 5:
        return faExclamationTriangle;
      default:
        return null;
    }
  };
});

const typeClass = computed(() => {
  return (type) => {
    let classes = ["alert", "alert-dismissible", "fade", "show"];
    switch (type) {
      case 0:
        classes.push("alert-primary");
        return classes;
      case 1:
        classes.push("alert-secondary");
        return classes;
      case 2:
        classes.push("alert-success");
        return classes;
      case 5:
        classes.push("alert-danger");
        return classes;
      case 3:
        classes.push("alert-warning");
        return classes;
      case 4:
        classes.push("alert-info");
        return classes;
      case 6:
        classes.push("alert-light");
        return classes;
      case 7:
        classes.push("alert-dark");
        return classes;
      default:
        return classes;
    }
  };
});

onMounted(() => {
  timerId = setInterval(() => {
    for (let i = alerts.value.length - 1; i >= 0; i--) {
      if (
        alerts.value[i].timeRange &&
        alerts.value[i].timeRange <= Date.now()
      ) {
        alerts.value.splice(i, 1);
      }
    }
  }, timing);
});

onUnmounted(() => {
  clearInterval(timerId);
});

watch(
  () => props.data,
  (newValue) => {
    
    if (!newValue.code || isNaN(Number(newValue.code))) {
      return;
    }
    const alertObj = {
      type: Math.floor(newValue.code / 100),
      message: newValue.message,
      autoHide: newValue.autoHide,
    };

    if (newValue.autoHide) {
      alertObj.timeRange = newValue.timeRange + timing;
    } else {
      for (let i = alerts.value.length - 1; i >= 0; i--) {
        if (!alerts.value[i].timeRange) {
          alerts.value.splice(i, 1);
        }
      }
    }

    alerts.value.unshift(alertObj);
  },
  { deep: true }
);
</script>
