<template>
  <div class="clearfix">
    <p :id="'scrollspyHeading' + index" class="float-start">{{ title }}</p>
    <div
      class="btn-group float-end"
      role="group"
      aria-label="Basic outlined example"
    >
      <button type="button" class="btn btn-outline-primary" @click="emitApply">
        应用
      </button>
      <button type="button" class="btn btn-outline-primary" @click="emitSave">
        保存
      </button>
    </div>
  </div>
  <textarea v-model="text" class="form-control p-3 mb-4"></textarea>
</template>
<script setup>
import {
  onMounted,
  onUnmounted,
  defineProps,
  defineEmits,
  ref,
  watch,
  defineExpose,
} from "vue";
var props = defineProps({
  title: {
    type: String,
    required: true,
  },
  textJson: {
    type: String,
  },
  index: {
    type: Number,
    default: 999,
  },
  nextHandle: {
    type: String,
    default: "",
  },
});

watch(
  () => props.textJson,
  (newValue) => {
    text.value = newValue;
  },
  { deep: true }
);

const emit = defineEmits([]);
const text = ref("");
const handleApply = "handleApply";
const handleSave = "handleSave";

const isJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};

const emitApply = () => {
  if (!isJSON(text.value)) {
    alert("不是JSON格式");
    return;
  }
  const data = {
    index: props.index,
    nextHandle: props.nextHandle,
    value: text.value,
  };
  emit(handleApply, data);
};

const emitSave = () => {
  if (!isJSON(text.value)) {
    alert("不是JSON格式");
    return;
  }
  const data = {
    index: props.index,
    nextHandle: props.nextHandle,
    value: text.value,
  };
  emit(handleSave, data);
};

onMounted(() => {
  text.value = props.textJson;
});

onUnmounted(() => {});

// 暴露方法，以便父组件可以调用
defineExpose({ isJSON });
</script>
