import axios from 'axios';
import router from '@/router'; // 导入路由实例
import { userStore } from "@/stores/user";

// function delay(ms) {  
//   return new Promise(resolve => setTimeout(resolve, ms));  
// }  

const base_url = '/api';

// 创建 axios 实例
const api = axios.create({
  baseURL: base_url, // 设置请求基地址
  //timeout: 5000, // 设置超时时间
  withCredentials: true
});

// 请求拦截器
api.interceptors.request.use(config => {
  const myStore = userStore();
  if(myStore.storage){
    // 获取 token
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // 添加授权头
    }
  }
  return config;
});

// 响应拦截器
api.interceptors.response.use(response => {
  const myStore = userStore();
  if (myStore.storage && (response?.data?.data?.token)) {
    localStorage.setItem(myStore.storage, response.data.data.token);
  }
  return response;
}, error => {
  //console.log(error);
  // 错误处理
  if (error?.response?.status === 401) {
    const myStore = userStore();
    myStore.cleanCurrentUser();
    // 未授权，跳转到登录页面
    router.push('/login'); // 使用路由跳转到 /login 页面
  }
  return Promise.reject(error);
});

export const newOrderURL = new URL('/order/new', process.env.VUE_APP_API_URL).toString();  

export const axiosGoTest = () => api.get('/test');//await delay(5);
export const axiosSSETest = async() => await api.get('/order/sse');

export const axiosLogin = async(data) => await api.post('/offline/login', data);
export const axiosSendEmail = async(data) => await api.post('/offline/email', data);
export const axiosGetEmail = async(email,url) => {
  const filter = encodeURIComponent(url)
  return await api.get(`/admin/email/${email}?url=${filter}`)
};
export const axiosExistUser = async(name) => await api.get(`/exist/${name}`);
export const axiosUploadFile = async(data, config) => await api.post('/vip/upload', data, config);
export const axiosGetUser = async(id) => await api.get(`/visitor/user/${id}`);
export const axiosGetAllUsers = async(data) => {
  const filter = encodeURIComponent(JSON.stringify(data))
  return await api.get(`/admin/user?filter=${filter}`)
};
export const axiosRegister = async(data) => await api.post('/offline/register', data);
export const axiosResetPassword = async(data) => await api.patch('/offline/resetpassword', data);
export const axiosSetPassword = async(data) => await api.patch('/visitor/user/password', data);
export const axiosSetUserEnabled = async(data) => await api.patch(`/admin/user/enabled`, data);
export const axiosGetPlan = async(code,islogin) => {
if(islogin){
  return await api.get(`/visitor/plan/code/${code}`);
}
  return await api.get(`/offline/plan/code/${code}`);
};//可登可不登
export const axiosGetOwnPlans = async(code) => await api.get(`/visitor/plan/own${code?'/'+code:''}`);
export const axiosGetPartPlans = async() => await api.get(`/offline/plan`);
export const axiosExistPlan = async(hash) => await api.get(`/vip/plan/filehash/${hash}`);
export const axiosIsPublicPlan = async(code) => await api.get(`/offline/plan/ispublic/${code}`);
export const axiosCreatePlan = async(data) => await api.post('/vip/plan', data);
//export const axiosUpdatePlan = async(id, data) => await api.put(`/vip/plan/${id}`, data);
export const axiosDeletePlan = async(id) => await api.delete(`/visitor/plan/${id}`);
export const axiosGetOwnOrders = async() => await api.get(`/visitor/order`);
export const axiosGetAllOrders = async(data) => {
  const filter = encodeURIComponent(JSON.stringify(data))
  return await api.get(`/admin/order?filter=${filter}`)
};
export const axiosCreateOrder = async(data) => await api.post('/visitor/order', data);
export const axiosSetOrdersEnabled = async(data) => await api.patch(`/admin/order/enabled`, data);
export const axiosGetSiteNodes = async(limit,data) => {
  const filter = encodeURIComponent(JSON.stringify(data))
  return await api.get(`/offline/sitenode/${limit}?filter=${filter}`)
};
export const axiosUpdateNavigation = async(data) => await api.patch('/admin/sitenode/navigation', data);
export const axiosUpdateAdvertising = async(data) => await api.patch('/admin/sitenode/advertising', data);
export const axiosUpdateOrderPayWay = async(data) => await api.patch('/admin/sitenode/orderpayway', data);
export const axiosUpdateOrderOrderType = async(data) => await api.patch('/admin/sitenode/orderordertype', data);
export const axiosUpdateOrderOrderStatus = async(data) => await api.patch('/admin/sitenode/orderorderstatus', data);
export const axiosUpdateTopUpOrderType = async(data) => await api.patch('/admin/sitenode/topupordertype', data);
export const axiosUpdateTopUpPayWay = async(data) => await api.patch('/admin/sitenode/topuppayway', data);
export const axiosSpeechToText = async(data) => await api.post('/vip/speech2text', data);
//export const axiosGetNavigation = async() => await api.get(`/offline/sitenode/navigation`);

//export default instance;