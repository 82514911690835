import { defineStore } from 'pinia';
//import { usePersist } from 'pinia-plugin-persist';

export const userStore = defineStore('newbabeluser', {
  state: () => ({
    user: {
    id: '',
    name: '', 
    email: '',
    telephone: '',
    wechat: '',
    number: -1, 
    expireDate: 0, 
    isVIP: false, 
    vipLevel: 0, 
    permissionLevel: 0, //权限
    isEnabled: true,
    navs: [],
    },
    admin: 99,
    vip: 2,
    visitor: 1,
    nobody: 0,
    newO: 0,
  }),
  actions: {
    setCurrentUser(data) {
      for (const key in data) {
        // 检查user对象中是否存在该属性
        if (Object.prototype.hasOwnProperty.call(this.currentUser, key)) {
          // 将data对象中对应属性的值赋值给user对象
          this.currentUser[key] = data[key];
        }
      }
    },
    addNewOrderCount(data){
      if (!isNaN(data) && Number.isInteger(data)) {
        this.$state.newO += data;
      }
    },
    cleanNewOrderCount(){
      this.$state.newO = 0
    },  
    cleanCurrentUser(){
      localStorage.removeItem(this.storage); // 清除 token
      this.currentUser.id = ''
      this.currentUser.name = ''
      this.currentUser.email = ''
      this.currentUser.telephone = ''
      this.currentUser.wechat = ''
      this.currentUser.number = -1 
      this.currentUser.expireDate = 0
      this.currentUser.isVIP = false 
      this.currentUser.vipLevel = 0 
      this.currentUser.permissionLevel = 0 //权限
      this.currentUser.isEnabled = true
      this.currentUser.navs = []
      this.$state.newO = 0
    },
    isAdmin(){
      return this.currentUser.permissionLevel >= this.admin;
    },
    isVip(){
      return this.currentUser.permissionLevel >= this.vip;
    }
  },
  getters: {
    app:()=>'纽巴别塔',
    wechat:()=>'gaaguo',
    storage:(state)=>state.user.name,
    currentUser: (state) => state.user,
    newOrderCount:(state)=>state.newO,
  },
  persist: {
    key: 'newbabeluser',
    storage: sessionStorage
  }
});