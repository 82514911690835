<template>
  <HttpProxy ref="httpProxyRef" />
  <div class="container">
    <div class="modal-header" style="padding: 35px 50px">
      <h4><font-awesome-icon :icon="faUserPlus" /> 注册</h4>
      <div class="bubble text-bg-success">现在注册送7天会员</div>
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="mb-3">
        <input
          type="email"
          :class="{
            'form-control': true,
            'is-invalid': checkEmail.invalid == 1,
            'is-valid': checkEmail.invalid == 2,
            'has-validation': true,
          }"
          placeholder="邮箱"
          v-model="username"
          @blur="handleBlur"
          required
        />
        <div
          :class="{
            'invalid-feedback': checkEmail.invalid === 1,
            'valid-feedback': checkEmail.invalid === 2,
          }"
        >
          {{ checkEmail.msg }}
        </div>
      </div>
      <div class="captcha-group row g-3">
        <div class="col-auto">
          <input
            id="captcha"
            type="text"
            :class="{
              'form-control': true,
              'is-invalid': checkCaptcha,
            }"
            placeholder="验证码"
            v-model="captcha"
            required
          />
          <div class="invalid-feedback">{{ checkCaptcha }}</div>
        </div>
        <div class="col-auto">
          <CaptchaComp ref="captchaRef" />
        </div>
      </div>
      <br />
      <div class="text-center">
        <button type="submit" class="btn btn-primary" :disabled="!IsExistEmail">
          注册
        </button>
      </div>
    </form>
    <br />
    <div>
      <a href="/login" class="jump-register">&lt;返回登录</a>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "vue-router";
import CaptchaComp from "@/components/CaptchaComp.vue";
import HttpProxy from "@/components/HttpProxy.vue";

// 定义响应式状态
const username = ref("");
const captcha = ref("");
const captchaRef = ref(null);
const httpProxyRef = ref(null);
const router = useRouter();

const checkCaptcha = ref('');

const checkEmail = reactive({
  invalid: 0,
  msg: "",
});

const IsExistEmail = computed(() => {
  return checkEmail.invalid === 2;
});

const validateEmail = (email) => {
  // 正则表达式用于匹配电子邮件格式
  var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // 测试给定的电子邮件字符串是否符合模式
  return emailPattern.test(email);
};

// 定义方法
const handleSubmit = async () => {
  if (!captchaRef.value || !captchaRef.value.code) {
    checkCaptcha.value = "请输入验证码";
    return;
  }
  if (captcha.value != captchaRef.value.code) {
    checkCaptcha.value = "验证码错误，请重新输入";
    return;
  }
  checkCaptcha.value = "";
  const origin = window.location.origin;
  const fullUrl = `${origin}/resetpwd`;
  const data = {
    url: fullUrl,
    name: username.value,
  };
  //console.log(data);
  if (httpProxyRef.value && httpProxyRef.value.sendEmail) {
    const response = await httpProxyRef.value.sendEmail(data);
    if (!response) {
      return;
    }
    //console.log(response);
    if (response.status === 200) {
      //console.log(response);
      alert(response.data.message);
      
    } else {
      console.error("未知错误");
      router.push("/");
    }
  }
};

const handleBlur = async () => {
  if (!username.value) {
    return;
  }
  if (httpProxyRef.value && httpProxyRef.value.existUser) {
    const response = await httpProxyRef.value.existUser(username.value);
    //console.log(response);
    if (response.status === 200) {
      //console.log(JSON.stringify(response.data, null, 4));
      if (response.data.data.IsExist) {
        checkEmail.invalid = 1;
        checkEmail.msg = "已存在用户名";
      } else if (!validateEmail(username.value)) {
        checkEmail.invalid = 1;
        checkEmail.msg = "请输入正确的邮箱地址";
      } else if (response.data.data.IsExist === false) {
        checkEmail.invalid = 2;
        checkEmail.msg = "可注册用户名";
      } else {
        checkEmail.invalid = 0;
        checkEmail.msg = "";
      }
    } else {
      console.error("未知错误");
    }
  }
};

// 组件挂载后刷新验证码
onMounted(() => {});
</script>

<style scoped>
.captcha-group {
  display: flex;
  align-items: center;
}

.captcha-input {
  flex-grow: 1; /* 输入框占据剩余空间 */
}

.bubble {
  position: relative;
  border-radius: 8px;
  padding: 10px;
  width: fit-content;
  max-width: 80%;
  margin-left: 10px;
  opacity: 0.1;
  transition: opacity 0.3s ease-in-out;
}
.bubble:hover {
  opacity: 1;
}

.bubble::before {
  content: "";
  position: absolute;
  right: 100%; /* 将尾巴放在气泡的左侧 */
  top: 50%;
  transform: translateY(-50%);
  border-style: solid;
  border-width: 10px 10px 10px 0; /* 上 右 下 左 */
  border-color: transparent #198754 transparent transparent;
}

/* .bubble::after {
  content: "";
  position: absolute;
  right: calc(100% - 1px); /* 确保阴影与主气泡对齐
  top: 50%;
  transform: translateY(-50%);
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent transparent transparent transparent;
} */
</style>
