  async function extractAudioSegment(file, startTime, endTime) {
    const audioContext = new AudioContext(); //new (window.AudioContext || window.webkitAudioContext)();
    const arrayBuffer = await file.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  
    const startSample = Math.floor(startTime * audioBuffer.sampleRate);
    const endSample = Math.floor(endTime * audioBuffer.sampleRate);
    const channelCount = audioBuffer.numberOfChannels;
    const segment = audioBuffer.getChannelData(0).slice(startSample, endSample);
  
    const segmentBuffer = audioContext.createBuffer(
      channelCount,
      segment.length,
      audioBuffer.sampleRate
    );
  
    var anotherArray = new Float32Array(segment);
    //声道的数据的复制和写入
    var offset = 0;
    for (var channel = 0; channel < channelCount; channel++) {
      audioBuffer.copyFromChannel(anotherArray, channel, startSample);
      segmentBuffer.copyToChannel(anotherArray, channel, offset);
    }
  
    //segmentBuffer.copyToChannel(segment, 0);
    var blob = bufferToWave(segmentBuffer, segment.length);
    return blob;
  }

  // Convert AudioBuffer to a Blob using WAVE representation
  function bufferToWave(abuffer, len) {
    var numOfChan = abuffer.numberOfChannels,
      length = len * numOfChan * 2 + 44,
      buffer = new ArrayBuffer(length),
      view = new DataView(buffer),
      channels = [],
      i,
      sample,
      offset = 0,
      pos = 0;
  
    // write WAVE header
    // "RIFF"
    setUint32(0x46464952);
    // file length - 8
    setUint32(length - 8);
    // "WAVE"
    setUint32(0x45564157);
    // "fmt " chunk
    setUint32(0x20746d66);
    // length = 16
    setUint32(16);
    // PCM (uncompressed)
    setUint16(1);
    setUint16(numOfChan);
    setUint32(abuffer.sampleRate);
    // avg. bytes/sec
    setUint32(abuffer.sampleRate * 2 * numOfChan);
    // block-align
    setUint16(numOfChan * 2);
    // 16-bit (hardcoded in this demo)
    setUint16(16);
    // "data" - chunk
    setUint32(0x61746164);
    // chunk length
    setUint32(length - pos - 4);
  
    // write interleaved data
    for (i = 0; i < abuffer.numberOfChannels; i++)
      channels.push(abuffer.getChannelData(i));
  
    while (pos < length) {
      // interleave channels
      for (i = 0; i < numOfChan; i++) {
        // clamp
        sample = Math.max(-1, Math.min(1, channels[i][offset]));
        // scale to 16-bit signed int
        sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0;
        // write 16-bit sample
        view.setInt16(pos, sample, true);
        pos += 2;
      }
      // next source sample
      offset++;
    }
  
    // create Blob
    return new Blob([buffer], { type: "audio/wav" });
  
    function setUint16(data) {
      view.setUint16(pos, data, true);
      pos += 2;
    }
  
    function setUint32(data) {
      view.setUint32(pos, data, true);
      pos += 4;
    }
  }

  function arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
  
  export { extractAudioSegment, arrayBufferToBase64 };