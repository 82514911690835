<template>
  <div class="container">
    <HttpProxy ref="httpProxyRef" />
    <div class="table-responsive-xxl">
      <table class="table table-striped">
        <thead>
          <tr>
            <th
              v-for="(header, index) in tableHeaders"
              :key="index"
              @click="sortTable(header)"
            >
              {{ header }}
              <span v-if="currentSortColumn === header">
                <font-awesome-icon :icon="typeIcon(currentSortOrder)"
              /></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in jsonData" :key="index">
            <!-- 动态生成列，假设所有行具有相同的数据结构 -->
            <td v-for="(value, key) in item" :key="key">
              <span :class="typeClass(key, value)">{{ value }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import HttpProxy from "@/components/HttpProxy.vue";

const httpProxyRef = ref(null);
// 排序参数
const currentSortColumn = ref(null);
const currentSortOrder = ref(0);

const jsonData = ref([]);

const typeIcon = computed(() => {
  return (sort) => {
    switch (sort) {
      case 1:
        return faAngleDown;
      case 2:
        return faAngleUp;
      default:
        return null;
    }
  };
});
//计算样式
const typeClass = computed(() => {
  return (key, value) => {
    if (key != "订单状态") {
      return "";
    }
    let classes = ["badge"];
    switch (value) {
      case "处理中":
        classes.push("text-bg-warning");
        return classes;
      case "完成":
        classes.push("text-bg-success");
        return classes;
      case "无效":
        classes.push("text-bg-dark");
        return classes;
      default:
        return classes;
    }
  };
});

// 计算表头
const tableHeaders = computed(() => {
  const headers = [];
  if (jsonData.value.length > 0) {
    Object.keys(jsonData.value[0]).forEach((key) => {
      headers.push(key);
    });
  }
  return headers;
});

// 数据排序函数
const sortTable = (header) => {
  if (currentSortColumn.value === header) {
    currentSortOrder.value = currentSortOrder.value === 1 ? 2 : 1;
  } else {
    currentSortOrder.value = 1;
    currentSortColumn.value = header;
  }
  jsonData.value.sort((a, b) => {
    if (a === null || b === null || a === undefined || b === undefined) {
      return 0; // 如果任何一个为 null 或 undefined，则认为它们相等
    }

    // 检查 a 和 b 是否有 header 属性
    const aValue = a[header]||null;
    const bValue = b[header]||null;
    if (aValue < bValue) return currentSortOrder.value === 1 ? -1 : 1;
    if (aValue > bValue) return currentSortOrder.value === 1 ? 1 : -1;
    return 0;
  });
};

onMounted(async () => {
  if (httpProxyRef.value && httpProxyRef.value.getOwnOrders) {
    const response = await httpProxyRef.value.getOwnOrders();
    if (!response) {
      return;
    }
    //console.log(response);
    if (response.status === 200) {
      //console.log(JSON.stringify(response.data.data, null, 4));
      jsonData.value = response.data.data.data;
    } else {
      console.error("未知错误");
    }
  }
});
</script>
