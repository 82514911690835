<template>
  <div class="captcha">
    <canvas
      ref="canvas"
      @click="generateCaptcha"
      :width="width"
      :height="height"
    ></canvas>
  </div>
</template>
<script setup>
import { ref, onMounted, defineExpose } from "vue";

const width = 100;
const height = 30;
const canvas = ref(null);
const code = ref("");

const generateCaptcha = () => {
  const ctx = canvas.value.getContext("2d");
  ctx.clearRect(0, 0, width, height);

  // 生成随机验证码
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let newCode = "";
  for (let i = 0; i < 4; i++) {
    newCode += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  code.value = newCode;

  // 绘制背景
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, width, height);

  // 绘制文本
  ctx.font = "20px Arial";
  ctx.fillStyle = "black";
  ctx.fillText(code.value, 10, 25);

  // 添加干扰线
  for (let i = 0; i < 5; i++) {
    ctx.strokeStyle = "gray";
    ctx.beginPath();
    ctx.moveTo(Math.random() * width, Math.random() * height);
    ctx.lineTo(Math.random() * width, Math.random() * height);
    ctx.stroke();
  }

  // 添加干扰点
  for (let i = 0; i < 20; i++) {
    ctx.fillStyle = "gray";
    ctx.beginPath();
    ctx.arc(Math.random() * width, Math.random() * height, 2, 0, 2 * Math.PI);
    ctx.fill();
  }
};

onMounted(() => {
  generateCaptcha();
});

// 暴露方法，以便父组件可以调用
defineExpose({ code });

</script>
<style scoped>
.captcha {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  cursor: pointer; /* 鼠标悬停时变为手型 */
}
</style>
