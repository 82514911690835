<template>
  <div class="container text-center d-grid gap-3">
    <div class="text-start">
      <a href="/record"
        ><font-awesome-icon :icon="faAngleDoubleLeft" />返回记录</a
      >
    </div>
    <HttpProxy ref="httpProxyRef" />
    <div class="input-group has-validation" role="group">
      <input
        type="file"
        accept="audio/*"
        ref="audioInput"
        :class="{ 'form-control': true, 'is-invalid': checkFile }"
        required
      />
      <button
        :class="{
          btn: true,
          'btn-outline-primary': true,
          disabled: !myStore.currentUser.isVIP && !canUpload,
        }"
        type="button"
        @click="handleSubmit"
      >
        保存
      </button>
      <div class="invalid-feedback">{{ checkFile }}</div>
    </div>
    <div class="audio-list row">
      <template v-if="audioArray.length > 0">
        <ol class="list-group list-group-numbered">
          <div v-for="(item, index) in audioArray" :key="index">
            <li class="list-group-item">
              <AudioPlayer
                :index="index"
                :file="item.file"
                :startTime="item.startTime"
                :endTime="item.endTime"
                :canZh="
                  myStore.currentUser.permissionLevel >= 10 &&
                  myStore.currentUser.isVIP
                "
                @add-audio="addAudio"
                @merge-audio="mergeAudio"
                @to-text="speechToText"
              />
              <div class="title">
                <strong
                  v-if="item.title.trim().length && index != currTitle"
                  @click="toggleTitle(index)"
                  >{{ item.title }}</strong
                >
                <input
                  v-else
                  type="text"
                  v-model="item.title"
                  @click="toggleTitle(index)"
                  @blur="lostTitle(index)"
                />
                <strong
                  v-if="item.subtitle.trim().length && index != currSubtitle"
                  @click="toggleSubtitle(index)"
                  >{{ item.subtitle }}</strong
                >
                <input
                  v-else
                  type="text"
                  v-model="item.subtitle"
                  @click="toggleSubtitle(index)"
                  @blur="lostSubtitle(index)"
                />
              </div>
            </li>
          </div>
        </ol>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { userStore } from "@/stores/user";
import HttpProxy from "@/components/HttpProxy.vue";
import AudioPlayer from "../components/AudioPlayer.vue";
import CryptoJS from "crypto-js"; // 引入 crypto-js 库
import { useRouter } from "vue-router";
import { extractAudioSegment, arrayBufferToBase64 } from "@/script/utils.js";

const m_maxsize = 20;
const m_vip_maxsize = 50;

const router = useRouter();
const myStore = userStore();
const httpProxyRef = ref(null);
const fileSrc = ref("");
const audioArray = ref([]);
const currTitle = ref(-1);
const currSubtitle = ref(-1);
const audioInput = ref(null);
const fileEntity = ref(null);
const checkFile = ref("");
const maxLength = ref(10);
const maxTimeRange = 60;
const canUpload = ref(true);

// 计算属性用于在模板中更方便地访问数组长度
const audioArrayLength = computed(() => audioArray.value.length);

onMounted(() => {
  audioInput.value.addEventListener("change", (event) => {
    if (!event.target.files[0]) {
      fileEntity.value = null;
      fileSrc.value = "";
      audioArray.value = [];
      return;
    }
    handleFileChange(event);
  });

  audioInput.value.addEventListener("cancel", () => {});
});

// 在组件卸载之前清理音频
onBeforeUnmount(() => {
  const audio = new Audio(fileSrc.value);
  audio.pause();
  audio.src = "";
});

const toggleTitle = (index) => {
  currTitle.value = index;
  currSubtitle.value = -1;
};

const toggleSubtitle = (index) => {
  currSubtitle.value = index;
  currTitle.value = -1;
};

const lostTitle = (index) => {
  if (currTitle.value === index) {
    currTitle.value = -1;
  }
};

const lostSubtitle = (index) => {
  if (currSubtitle.value === index) {
    currSubtitle.value = -1;
  }
};

const handleSubmit = async () => {
  if (!fileEntity.value) {
    checkFile.value = "请选择文件";
    return;
  }

  const hash = CryptoJS.SHA256(
    CryptoJS.lib.WordArray.create(await fileEntity.value.arrayBuffer())
  );
  const fileHash = hash.toString(CryptoJS.enc.Hex);
  //console.log("文件哈希值:", fileHash);

  if (!fileEntity.value.type.startsWith("audio/")) {
    // 清除文件选择框
    audioInput.value.value = "";
    checkFile.value = "请选择音频文件";
    return;
  }

  if (
    (myStore.currentUser.isVIP &&
      fileEntity.value.size > 1024 * 1024 * m_vip_maxsize) ||
    (!myStore.currentUser.isVIP &&
      fileEntity.value.size > 1024 * 1024 * m_maxsize)
  ) {
    checkFile.value = `音频文件太大了，请选择${
      myStore.currentUser.isVIP ? m_vip_maxsize : m_maxsize
    }M以内的文件`;
    return;
  }

  checkFile.value = "";

  //我是否已经有
  if (httpProxyRef.value && httpProxyRef.value.existPlan) {
    const response = await httpProxyRef.value.existPlan(fileHash);
    //console.log(response);
    try {
      if (response.status === 200) {
        if (
          response.data.data.IsExist &&
          !confirm("已经有这个文件的记录，要覆盖原来的记录吗？")
        ) {
          return;
        }
      } else {
        console.error("未知错误");
        return;
      }
    } catch (error) {
      console.error(error);
      return;
    }
  }

  const formData = new FormData();
  formData.append("file", fileEntity.value);

  if (httpProxyRef.value && httpProxyRef.value.uploadFile) {
    canUpload.value = false;
    const response = await httpProxyRef.value.uploadFile(formData);
    //console.log(response);
    try {
      if (response.status != 200 && response.status != 201) {
        console.error("未知错误");
        return;
      }
    } catch (error) {
      canUpload.value = true;
      console.error(error);
      return;
    }
  }

  const tempAudioArray = structuredClone(audioArray.value);
  tempAudioArray.forEach((item) => {
    item.file = null;
  });

  // 去除扩展名
  const fileName = fileEntity.value.name
    .substring(0, fileEntity.value.name.lastIndexOf("."))
    .replace(/_/g, " ");
  const data = {
    file_hash: fileHash,
    title: fileName,
    record: JSON.stringify(tempAudioArray),
    praise: 0,
    image: "",
    is_public: false,
  };

  if (httpProxyRef.value && httpProxyRef.value.createPlan) {
    const response = await httpProxyRef.value.createPlan(data);
    //console.log(response);
    try {
      if (response.status === 201 || response.status === 200) {
        router.push({
          path: "/record",
          query: { code: response.data.data.code },
        });
      } else {
        console.error("未知错误");
      }
    } catch (error) {
      console.error(error);
    }
  }

  canUpload.value = true;
};

const handleFileChange = async (event) => {
  const file = event.target.files[0];

  if (!file) {
    return;
  }

  if (!file.type.startsWith("audio/")) {
    // 清除文件选择框
    audioInput.value.value = "";
    checkFile.value = "请选择音频文件";
    return;
  }

  if (
    (myStore.currentUser.isVIP && file.size > 1024 * 1024 * m_vip_maxsize) ||
    (!myStore.currentUser.isVIP && file.size > 1024 * 1024 * m_maxsize)
  ) {
    checkFile.value = `音频文件太大了，请选择${
      myStore.currentUser.isVIP ? m_vip_maxsize : m_maxsize
    }M以内的文件`;
    return;
  }

  checkFile.value = "";
  fileEntity.value = file;
  // //console.log(file.name);
  // //console.log(file.size);
  const reader = new FileReader();
  //const hasher = new FileReader();

  reader.onload = async (e) => {
    const arrayBuffer = e.target.result;
    const audio = new Audio();
    fileSrc.value = audio.src = arrayBuffer;
    audio.onloadedmetadata = function () {
      //console.log("音频时长:", audio.duration, "秒");
    };
    audio.oncanplaythrough = function () {
      // 音频数据加载完成，可以开始播放
      audioArray.value.splice(0, audioArray.value.length);
      const newObj = {
        file: audio.src,
        startTime: 0,
        endTime: audio.duration,
        title: "",
        subtitle: "",
      };
      audioArray.value.splice(0, 0, newObj);
      currTitle.value = currSubtitle.value = -1;
    };
  };
  reader.readAsDataURL(file);
  // hasher.onload = async () => {
  //   const fileContent = hasher.result;
  //   const hashBuffer = await window.crypto.subtle.digest(
  //     "SHA-256",
  //     fileContent
  //   );
  //   const hashArray = Array.from(new Uint8Array(hashBuffer));
  //   const hashHex = hashArray
  //     .map((b) => ("00" + b.toString(16)).slice(-2))
  //     .join("");
  //   //console.log("文件哈希值:", hashHex);
  //   fileHash.value = hashHex;
  // };
  //hasher.readAsArrayBuffer(file);
};

const addAudio = (val) => {
  if (!myStore.currentUser.isVIP && audioArrayLength.value > maxLength.value) {
    if (confirm("去开通会员")) {
      router.push({ path: "/topUp" });
    }
    return;
  }
  const prevObj = audioArray.value[val.index];
  const newObj = {
    file: prevObj.file,
    startTime: val.startTime,
    endTime: prevObj.endTime,
    title: "",
    subtitle: "",
  };
  prevObj.endTime = val.startTime;
  audioArray.value.splice(val.index + 1, 0, newObj);
};

const mergeAudio = (val) => {
  if (val.index === 0) {
    return;
  }
  const prevObj = audioArray.value[val.index - 1];
  const currObj = audioArray.value[val.index];
  prevObj.endTime = currObj.endTime;
  prevObj.title += " " + currObj.title;
  prevObj.subtitle += " " + currObj.subtitle;
  audioArray.value.splice(val.index, 1);
};

const speechToText = async (val) => {
  if (!fileEntity.value) {
    alert("请先上传文件");
    return;
  }
  const obj = audioArray.value[val.index];
  if (obj.endTime - obj.startTime > maxTimeRange) {
    alert("截取音频在1分钟内");
    return;
  }

  if (httpProxyRef.value && httpProxyRef.value.speechToText) {
    // 创建新的 AudioBuffer
    const blob = await extractAudioSegment(
      fileEntity.value,
      obj.startTime,
      obj.endTime
    );
    //console.log(URL.createObjectURL(blob));
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = {
        audioData: arrayBufferToBase64(e.target.result),
        format: "wav",
      };
      const response = await httpProxyRef.value.speechToText(data);
      //console.log(response);
      try {
        if (response.status === 200 && val.index < audioArrayLength.value) {
          audioArray.value[val.index].title = response.data.data.data.title;
          audioArray.value[val.index].subtitle =
            response.data.data.data.subtitle;
        } else {
          console.error("未知错误");
        }
      } catch (error) {
        console.error(error);
      }
    };
    reader.readAsArrayBuffer(blob);
    //   const audio = new Audio();
    // audio.src=URL.createObjectURL(blob);
    // audio.play();
  }
};
</script>

<style scoped>
.audio-list {
  display: flex;
  flex-direction: column;
  align-items: center; /* 垂直居中 */
}

.title {
  display: flex;
  flex-direction: column;
}
</style>
