import { createRouter, createWebHistory } from 'vue-router';
import { defineAsyncComponent } from 'vue';
import { userStore } from "@/stores/user";
//import { storeToRefs } from 'pinia'
import AboutView from '@/views/AboutView.vue';
import RecordView from '@/views/RecordView.vue';
// import AboutView from '@/views/AboutView.vue';
import LoginView from '@/views/LoginView.vue';
import RegisterView from '@/views/RegisterView.vue';
// import TestPageView from '@/views/TestPageView.vue';
import TopUpView from '@/views/TopUpView.vue';
// import SetPwdView from '@/views/SetPwdView.vue';
// import ResetPwdView from '@/views/ResetPwdView.vue';
// import UserView from '@/views/UserView.vue';
// import OrderView from '@/views/OrderView.vue';
// import SiteInformView from '@/views/SiteInformView.vue';
// import NavView from '@/views/NavView.vue';
import MyOrderView from '@/views/MyOrderView.vue';
// import SettingView from '@/views/SettingView.vue';
import SquareView from '@/views/SquareView.vue';
// import NotFoundView from '@/views/NotFoundView.vue';
import CreateView from '@/views/CreateView.vue';
// import ExclusiveView from '@/views/ExclusiveView.vue';
import ShareView from '@/views/ShareView.vue';

// nProgress.configure({
//   showSpinner: false
// })

const routes = [
  {
    path: '/',
    redirect: '/square' // 默认重定向到登录页
  },
  { path: '/about', component: AboutView},
  { path: '/create', component: CreateView,
    beforeEnter: (to, from, next) => {
    const myStore = userStore();
    // 检查是否已经登录
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      // 已经登录，重定向到主页
      next();
    } else {
      // 允许访问登录页
      next('/login'); 
    }
  }  },
  { path: '/record', component: RecordView,
    beforeEnter: (to, from, next) => {
    const myStore = userStore();
    // 检查是否已经登录
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      next();
    } else {
      next('/login'); 
    }
  } },
  { path: '/share', component: ShareView},
  { path: '/about', component: defineAsyncComponent(() => import('@/views/AboutView.vue')) },
  { path: '/login', component: LoginView,
    beforeEnter: (to, from, next) => {
    const myStore = userStore();
    // 检查是否已经登录
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      // 已经登录，重定向到主页
      next(false)
    } else {
      // 允许访问登录页
      next(); 
    }
  }  },
  { path: '/register', component: RegisterView,
    beforeEnter: (to, from, next) => {
    const myStore = userStore();
    // 检查是否已经登录
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      // 已经登录，重定向到主页
      next('/');
    } else {
      // 允许访问登录页
      next(); 
    }
  }  },
  { path: '/testpage', component: defineAsyncComponent(() => import('@/views/TestPageView.vue')) },
  { path: '/topup', component: TopUpView,
    beforeEnter: (to, from, next) => {
    const myStore = userStore();
    // 检查是否已经登录
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      next();
    } else {
      next('/login'); 
    }
  } },
  { path: '/setpwd', component: defineAsyncComponent(() => import('@/views/SetPwdView.vue')),
    beforeEnter: (to, from, next) => {
    const myStore = userStore();
    // 检查是否已经登录
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      // 已经登录，重定向到主页
      next();
    } else {
      // 允许访问登录页
      next('/login'); 
    }
  } },
  { path: '/resetpwd', component: defineAsyncComponent(() => import('@/views/ResetPwdView.vue')),
    beforeEnter: (to, from, next) => {
    const myStore = userStore();
    // 检查是否已经登录
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      // 已经登录，重定向到主页
      next(false)
    } else {
      // 允许访问登录页
      next(); 
    }
  }  },
  { path: '/users', component: defineAsyncComponent(() => import('@/views/UserView.vue')),
    beforeEnter: (to, from, next) => {
    const myStore = userStore();
    // 检查是否已经登录
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      next();
    } else {
      next('/login'); 
    }
  } },
  { path: '/orders', component: defineAsyncComponent(() => import('@/views/OrderView.vue')),
    beforeEnter: (to, from, next) => {
    const myStore = userStore();
    // 检查是否已经登录
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      next();
    } else {
      next('/login'); 
    }
  } },
  { path: '/siteinform', component: defineAsyncComponent(() => import('@/views/SiteInformView.vue')),
    beforeEnter: (to, from, next) => {
    const myStore = userStore();
    // 检查是否已经登录
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      next();
    } else {
      next('/login'); 
    }
  } },
  { path: '/nav', component: defineAsyncComponent(() => import('@/views/NavView.vue')) },
  { path: '/myorder', component: MyOrderView,
    beforeEnter: (to, from, next) => {
    const myStore = userStore();
    // 检查是否已经登录
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      next();
    } else {
      next('/login'); 
    }
  } },
  { path: '/setting', component: defineAsyncComponent(() => import('@/views/SettingView.vue')) },
  { path: '/square', component: SquareView },
  { path: '/exclusive', component: defineAsyncComponent(() => import('@/views/ExclusiveView.vue')),
    beforeEnter: (to, from, next) => {
    const myStore = userStore();
    // 检查是否已经登录
    const token = localStorage.getItem(myStore.storage);
    if (token) {
      next();
    } else {
      next('/login'); 
    }
  } },
  { path: '/:pathMatch(.*)*', component: defineAsyncComponent(() => import('@/views/NotFoundView.vue'))},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 全局前置守卫
// router.beforeEach((to, from) => {
//   nProgress.start()

//   const myStore = useDemoStore()
//   const { counter } = storeToRefs(myStore)
//   // 从 store 中获取其他值，再决定返回值
//   // 这里演示获取 store 中 counter 的值
//   console.log(`counter：${counter.value}`)
//   return true
// })

// // 全局后置钩子
// router.afterEach(() => {
//   nProgress.done(true)
// })

export default router;