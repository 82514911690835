<template>
  <HttpProxy ref="httpProxyRef" />
  <div class="container">
    <div class="modal-header" style="padding: 35px 50px">
      <h4><font-awesome-icon :icon="faSignIn" /> 登录</h4>
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="mb-3">
        <input
          type="email"
          class="form-control"
          v-model="formData.name"
          placeholder="邮箱"
          required
        />
      </div>
      <div class="mb-3">
        <input
          type="password"
          v-model="formData.password"
          :class="{ 'form-control': true, 'is-invalid': checkPwd }"
          placeholder="密码"
          required
        />
        <div class="invalid-feedback">{{ checkPwd }}</div>
      </div>
      <div>
        <a
          href=""
          class="float-start"
          data-bs-toggle="offcanvas"
          data-bs-target="#staticBackdrop"
          aria-controls="staticBackdrop"
          >忘记密码？</a
        >
        <label class="float-end"
          ><input type="checkbox" v-model="rememberMe" /> 记住我</label
        >
      </div>
      <br /><br />
      <div class="text-center">
        <button type="submit" class="btn btn-primary">登录</button>
      </div>
    </form>
    <br />
    <div>
      <a href="/register" class="jump-register">注册&gt;</a>
    </div>
  </div>
  <div
    class="offcanvas offcanvas-start"
    data-bs-backdrop="static"
    tabindex="-1"
    id="staticBackdrop"
    aria-labelledby="staticBackdropLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="staticBackdropLabel">找回密码</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div>
        <form @submit.prevent="handleSendEmail">
          <div class="mb-3">
            <input
              type="email"
              :class="{
                'form-control': true,
                'is-invalid': checkEmail.invalid == 1,
                'is-valid': checkEmail.invalid == 2,
                'has-validation': true,
              }"
              placeholder="邮箱"
              v-model="username"
              @blur="handleBlur"
            />
            <div
              :class="{
                'invalid-feedback': checkEmail.invalid === 1,
                'valid-feedback': checkEmail.invalid === 2,
              }"
            >
              {{ checkEmail.msg }}
            </div>
          </div>
          <div class="captcha-group row g-3">
            <div class="col-auto">
              <input
                type="text"
                :class="{
                  'form-control': true,
                  'is-invalid': checkCaptcha,
                }"
                placeholder="验证码"
                v-model="captcha"
                required
              />
              <div class="invalid-feedback">{{ checkCaptcha }}</div>
            </div>
            <div class="col-auto">
              <CaptchaComp ref="captchaRef" />
            </div>
          </div>
          <br />
          <div class="text-center">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="!IsExistEmail"
            >
              找回密码
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, defineEmits } from "vue";
import { userStore } from "@/stores/user";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "vue-router";
import CaptchaComp from "@/components/CaptchaComp.vue";
import HttpProxy from "@/components/HttpProxy.vue";

// 定义响应式状态
const emit = defineEmits([]);
const myStore = userStore();
const httpProxyRef = ref(null);
const rememberMe = ref(false);
const username = ref("");
const captcha = ref("");
const captchaRef = ref(null);
const router = useRouter();

const formData = reactive({
  name: "",
  password: "",
});

const checkCaptcha = ref("");

const checkEmail = reactive({
  invalid: 0,
  msg: "",
});

const checkPwd = ref("");

const IsExistEmail = computed(() => {
  return checkEmail.invalid === 2;
});

const handleBlur = async () => {
  if (!username.value) {
    return;
  }
  checkEmail.invalid = 0;
  checkEmail.msg = "";

  if (httpProxyRef.value && httpProxyRef.value.existUser) {
    const response = await httpProxyRef.value.existUser(username.value);
    //console.log(response);
    if (response.status === 200) {
      //console.log(JSON.stringify(response.data, null, 4));
      if (response.data.data.IsExist) {
        checkEmail.invalid = 2;
      } else if (response.data.data.IsExist === false) {
        checkEmail.invalid = 1;
        checkEmail.msg = "没有用户";
      } else {
        checkEmail.invalid = 0;
        checkEmail.msg = "";
      }
    } else {
      console.error("未知错误");
    }
  }
};

const setCurrentUser = (data) => {
  var user = {
    id: data.Id,
    name: data.Name,
    email: "",
    telephone: "",
    wechat: "",
    createDate: new Date(),
    number: -1,
    expireDate: data.ExpireDate,
    isVIP: data.IsVip,
    vipLevel: data.VipLevel,
    permissionLevel: data.PermissionLevel, //权限
    isEnabled: true,
    navs: data.Navigation,
  };
  myStore.setCurrentUser(user);
};

// 定义方法
const handleSubmit = async () => {
  checkPwd.value = "";
  const data = {
    name: formData.name,
    password: formData.password,
  };
  // const token = localStorage.getItem(myStore.storage);
  // alert(token);
  myStore.cleanCurrentUser();
  if (httpProxyRef.value && httpProxyRef.value.login) {
    const response = await httpProxyRef.value.login(data);
    //console.log(response);
    try {
      if (response.status === 200) {
        setCurrentUser(response.data.data.data);
        if (response.data.data.token) {
          localStorage.setItem(myStore.storage, response.data.data.token);
        }
        emit("handleNavBar",response.data.data.data.Navigation);
        if (rememberMe.value) {
          localStorage.setItem("newbabeluser", formData.name);
          localStorage.setItem("newbabelpwd", formData.password);
        } else {
          // 清除本地存储的登录信息
          localStorage.removeItem("newbabeluser");
          localStorage.removeItem("newbabelpwd");
        }
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        if (code) {
          router.push({ path: "/share", query: { code: code } });
          return;
        } else {
          router.push("/");
          return;
        }
      } else if (response.status === 406) {
        checkPwd.value = response.data.message;
      } else {
        console.error("未知错误");
      }
    } catch (error) {
      console.error(error);
    }
  }
};

const handleSendEmail = async () => {
  if (!captchaRef.value || !captchaRef.value.code) {
    checkCaptcha.value = "请输入验证码";
    return;
  }
  if (captcha.value != captchaRef.value.code) {
    checkCaptcha.value = "验证码错误，请重新输入";
    return;
  }
  checkCaptcha.value = "";

  const origin = window.location.origin;
  const fullUrl = `${origin}/resetpwd`;
  const data = {
    url: fullUrl,
    name: username.value,
  };
  //console.log(data);
  if (httpProxyRef.value && httpProxyRef.value.sendEmail) {
    const response = await httpProxyRef.value.sendEmail(data);
    if (!response) {
      return;
    }
    //console.log(response);
    if (response.status === 200) {
      //console.log(JSON.stringify(response.data.data, null, 4));
      alert(response.data.message);
      router.push("/");
    } else if (response.status === 406) {
      alert(response.data.message);
    } else {
      console.error("未知错误");
    }
  }
};

// 组件挂载后加载用户名和密码
onMounted(() => {
  formData.name = localStorage.getItem("newbabeluser") || "";
  formData.password = localStorage.getItem("newbabelpwd") || "";
  if (
    localStorage.getItem("newbabeluser") &&
    localStorage.getItem("newbabelpwd")
  ) {
    rememberMe.value = true;
  }
});
</script>
<style scoped>
.captcha-group {
  display: flex;
  align-items: center;
}

.captcha-input {
  flex-grow: 1; /* 输入框占据剩余空间 */
}
</style>
