<template>
  <div class="container text-center d-grid gap-3">
    <HttpProxy ref="httpProxyRef" />
    <div
      v-if="needLogin"
      class="alert alert-secondary text-center"
      role="alert"
    >
      需要登录才能查看内容&nbsp;&nbsp;&nbsp;&nbsp;<a
        href="#"
        @click="handleLogin"
        >去登录<font-awesome-icon :icon="faAngleDoubleRight"
      /></a>
    </div>
    <div v-else class="row">
      <div>
        <a
          :class="{
            'float-start': true,
            btn: true,
            'btn-primary': true,
            disabled: !myStore.currentUser.isVIP,
          }"
          href="#"
          @click="handleSubmit"
          :aria-disabled="!myStore.currentUser.isVIP"
          ><font-awesome-icon :icon="faSave" />保存
        </a>
        <a
          :class="{
            'float-end btn': true,
            'btn-danger': isPraise,
            'btn-secondary': !isPraise,
          }"
          href="#"
          @click="isPraise = !isPraise"
          ><font-awesome-icon :icon="faHeart"
        /></a>
      </div>
      <div class="mt-3 mb-2">
        <template v-if="audioArray.length > 0">
          <ol class="list-group list-group-numbered">
            <div v-for="(item, index) in audioArray" :key="index">
              <li class="list-group-item">
                <AudioPlayer
                  :index="index"
                  :file="item.file"
                  :startTime="item.startTime"
                  :endTime="item.endTime"
                  :canZh="false"
                  @add-audio="addAudio"
                  @merge-audio="mergeAudio"
                />
                <div class="title">
                  <strong
                    v-if="item.title.trim().length && index != currTitle"
                    @click="toggleTitle(index)"
                    >{{ item.title }}</strong
                  >
                  <input
                    v-else
                    type="text"
                    v-model="item.title"
                    @click="toggleTitle(index)"
                    @blur="lostTitle(index)"
                  />
                  <strong
                    v-if="item.subtitle.trim().length && index != currSubtitle"
                    @click="toggleSubtitle(index)"
                    >{{ item.subtitle }}</strong
                  >
                  <input
                    v-else
                    type="text"
                    v-model="item.subtitle"
                    @click="toggleSubtitle(index)"
                    @blur="lostSubtitle(index)"
                  />
                </div>
              </li>
            </div>
          </ol>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { userStore } from "@/stores/user";
import HttpProxy from "@/components/HttpProxy.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faAngleDoubleRight,
  faHeart,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import AudioPlayer from "../components/AudioPlayer.vue";

const myStore = userStore();
const httpProxyRef = ref(null);
const audioArray = ref([]);
const currTitle = ref(-1);
const currSubtitle = ref(-1);
const router = useRouter();
const planEntity = ref({});
const needLogin = ref(false);
const isPraise = ref(false);
const maxLength = ref(10);

// 计算属性用于在模板中更方便地访问数组长度
const audioArrayLength = computed(() => audioArray.value.length);

const getAudioArray = async (code) => {
  if (!code) {
    return;
  }
  if (httpProxyRef.value && httpProxyRef.value.getPlan) {
    const response = await httpProxyRef.value.getPlan(code);
    //console.log(response);
    try {
      if (response.status === 200) {
        planEntity.value = response.data.data.data;
        try {
          audioArray.value = JSON.parse(planEntity.value.record);
          var fileURI = planEntity.value.file_link;
          if (fileURI) {
            audioArray.value.forEach((item) => {
              item.file = fileURI;
            });
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.error("未知错误");
      }
    } catch (error) {
      console.error(error);
    }
  }
};

onMounted(async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const token = localStorage.getItem(myStore.storage);
  if (token) {
    await getAudioArray(code || "");
    return;
  }
  if (code && httpProxyRef.value && httpProxyRef.value.isPublicPlan) {
    var response = await httpProxyRef.value.isPublicPlan(code);
    if (response.status === 200 && !response.data.data.IsPublic) {
      //需要登录查看
      needLogin.value = true;
    } else if (response.status === 200 && response.data.data.IsPublic) {
      await getAudioArray(code);
    }
  }
});

const handleLogin = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  if (code) {
    router.push({ path: "/login", query: { code: code } });
    return;
  }
  router.push({ path: "/login" });
};

const handleSubmit = async () => {
  if (!planEntity.value.is_owns) {
    //不是我的询问
    if (httpProxyRef.value && httpProxyRef.value.existPlan) {
      const response = await httpProxyRef.value.existPlan(
        planEntity.value.file_hash
      );
      //console.log(response);
      try {
        if (response.status === 200) {
          if (
            response.data.data.IsExist &&
            !confirm("已经有这个文件的记录，要覆盖原来的记录吗？")
          ) {
            return;
          }
        } else {
          console.error("未知错误");
          return;
        }
      } catch (error) {
        console.error(error);
        return;
      }
    }
  }

  const tempAudioArray = structuredClone(audioArray.value);
  tempAudioArray.forEach((item) => {
    item.file = null;
  });

  planEntity.value.record = JSON.stringify(tempAudioArray);
  if (!planEntity.value.is_owns) {
    // 非自己创建的，默认不公开
    planEntity.value.is_public = false;
  }

  if (httpProxyRef.value && httpProxyRef.value.createPlan) {
    let data = structuredClone(planEntity.value);
    delete data._id;
    const response = await httpProxyRef.value.createPlan(data);
    //console.log(response);
    try {
      if (response.status === 201 || response.status === 200) {
        router.push({
          path: "/record",
          query: { code: response.data.data.code },
        });
      } else {
        console.error("未知错误");
      }
    } catch (error) {
      console.error(error);
    }
  }
};

const toggleTitle = (index) => {
  currTitle.value = index;
  currSubtitle.value = -1;
};

const toggleSubtitle = (index) => {
  currSubtitle.value = index;
  currTitle.value = -1;
};

const lostTitle = (index) => {
  if (currTitle.value === index) {
    currTitle.value = -1;
  }
};

const lostSubtitle = (index) => {
  if (currSubtitle.value === index) {
    currSubtitle.value = -1;
  }
};

const addAudio = (val) => {
  if (!myStore.currentUser.isVIP && audioArrayLength.value > maxLength.value) {
    if (confirm("去开通会员")) {
      router.push({ path: "/topUp" });
    }
    return;
  }
  const prevObj = audioArray.value[val.index];
  const newObj = {
    file: prevObj.file,
    startTime: val.startTime,
    endTime: prevObj.endTime,
    title: "",
    subtitle: "",
  };
  prevObj.endTime = val.startTime;
  audioArray.value.splice(val.index + 1, 0, newObj);
};

const mergeAudio = (val) => {
  if (val.index === 0) {
    return;
  }
  const prevObj = audioArray.value[val.index - 1];
  const currObj = audioArray.value[val.index];
  prevObj.endTime = currObj.endTime;
  prevObj.title += " " + currObj.title;
  prevObj.subtitle += " " + currObj.subtitle;
  audioArray.value.splice(val.index, 1);
};
</script>

<style scoped>
.audio-list {
  display: flex;
  flex-direction: column;
  align-items: center; /* 垂直居中 */
}

.title {
  display: flex;
  flex-direction: column;
}
</style>
