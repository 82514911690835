<template>
  <div>
    <keep-alive>
      <NavBar :data="navBarData" />
    </keep-alive>
    <AdPosition :data="images" :carouselWidth="500" />
    <HttpProxy ref="httpProxyRef" />
    <div class="container-fluid mt-5">
      <div class="row content">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <router-view @handleAdver="setAdPosition" @handleNavBar="setNavBar" />
        </div>
        <div class="col-sm-2"></div>
        <footer class="container-fluid bg-primary-subtle text-center py-3 mt-5">
          <div class="container">
            <div class="d-flex justify-content-center">
              <div class="hstack gap-3">
                <a
                  :href="icpUrl"
                  class="a-icp text-muted text-decoration-none"
                  target="_blank"
                  >{{ icpCode }}</a
                >
                <div class="vr"></div>
                <a href="about">关于</a>
              </div>
            </div>
          </div>
          <div class="container-fluid text-start">
            <div class="row">
              <!-- <p>
                联系微信:
                <span class="glyphicon glyphicon-envelope"></span>
                {{ myStore.wechat }}
              </p> -->
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import NavBar from "./components/NavBar.vue";
import AdPosition from "./components/AdPosition.vue";
import HttpProxy from "@/components/HttpProxy.vue";

const httpProxyRef = ref(null);
const navBarData = ref([]);
const images = ref([]);
const icpUrl = process.env.VUE_APP_ICP_URL;
const icpCode = process.env.VUE_APP_ICP_CODE;

const setAdPosition = (data) => {
  images.value = data;
};

const setNavBar = (data) => {
  navBarData.value = data;
};

onMounted(async () => {
  // 使用 Promise 延迟执行请求
  new Promise((resolve) => {
    setTimeout(resolve, 1000);
  }).then(async () => {
    if (httpProxyRef.value && httpProxyRef.value.getSiteNodes) {
      const data = ["advertising"];
      const response = await httpProxyRef.value.getSiteNodes(0, data);
      //console.log(response);
      try {
        if (response.status != 200) {
          console.error("未知错误");
          return false;
        }
        const imgStr = response.data.data.data.advertising;
        images.value = JSON.parse(imgStr);
      } catch (error) {
        console.error(error);
      }
    }
  });
});
</script>
<style>
/* Set height of the grid so .sidenav can be 100% (adjust as needed) */
.row.content {
  height: 450px;
}

/* button {
  background-color: #2C63ae; 
  color: #F9F9F9; 
}

.btn-primary {
  background-color: #ff0000; 
  color: #F9F9F9; 
} */

.a-icp {
  font-size: 12px; /* 设置字体大小为12px */
}

/* Set gray background color and 100% height */
.sidenav {
  padding-top: 20px;
  background-color: #f1f1f1;
  height: 100%;
}

/* On small screens, set height to 'auto' for sidenav and grid */
@media screen and (max-width: 767px) {
  .sidenav {
    height: auto;
    padding: 15px;
  }
  .row.content {
    height: auto;
  }
}
</style>
