import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from '@/App.vue';
import router from '@/router'; // 确保这里的路径正确
import 'bootstrap'
import '@/global.css';

const app = createApp(App);
const pinia = createPinia();
// 使用持久化插件
pinia.use(piniaPluginPersistedstate);
app.use(router);
app.use(pinia);
app.mount('#app');