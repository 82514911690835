<template>
  <div v-if="showAd && data.length > 0" class="text-center">
    <a href="#" @click="closeAd">关闭广告</a>
    <div
      v-if="data.length > 0"
      @mouseover="stopAutoPlay"
      @mouseout="startAutoPlay"
      class="carousel carousel-dark slide"
    >
      <div class="carousel-inner">
        <a
          :href="data[currentIndex].url"
          :target="data[currentIndex].url === '#' ? '' : '_blank'"
        >
          <div class="carousel-item active">
            <img
              :src="data[currentIndex].img"
              class="d-block w-100"
              :alt="`image: ${currentIndex + 1}`"
            />
            <div class="carousel-caption d-none d-md-block">
              <h5 class="title-text">{{ data[currentIndex].title }}</h5>
              <p class="title-text">
                {{ data[currentIndex].subtitle }}
              </p>
            </div>
          </div>
        </a>
      </div>
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="1"
          aria-label="Slide 2"
          v-for="(image, index) in data"
          :key="index"
          :class="{ active: index === currentIndex }"
          @click="setCurrentIndex(index)"
        ></button>
      </div>
      <button
        @click="setCurrentIndex((currentIndex - 1 + data.length) % data.length)"
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        @click="setCurrentIndex((currentIndex + 1) % data.length)"
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, onMounted, onUnmounted } from "vue";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
});

// 监听 data 的变化
watch(
  () => props.data,
  (newData,) => {
    showAd.value = newData.length > 0;
    // 在这里处理数据变化的逻辑
  },
  { deep: true }
);

const currentIndex = ref(0);
const showAd = ref(true);
const autoPlayInterval = ref(null);

const closeAd = () => {
  showAd.value = false;
};
const nextSlide = () => {
  currentIndex.value = (currentIndex.value + 1) % props.data.length;
};

const setCurrentIndex = (index) => {
  currentIndex.value = index;
};

const startAutoPlay = () => {
  autoPlayInterval.value = setInterval(nextSlide, 3000);
};

const stopAutoPlay = () => {
  clearInterval(autoPlayInterval.value);
};

onMounted(() => {
  startAutoPlay();
});

onUnmounted(() => {
  clearInterval(autoPlayInterval.value);
});
</script>

<style scoped>
.title-text {
  color: white;
  text-shadow: 0 0 10px #000; /* 添加文字阴影 */
}

.carousel {
  position: relative;
  overflow: hidden;
  width: auto;
  margin: 0 auto;
}

.carousel-container {
  display: flex;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.carousel-item {
  flex-shrink: 0;
  overflow: hidden;
  height: 300px;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicators span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.indicators .active {
  background-color: #333;
}
</style>
