<template>
  <div class="container text-center d-grid gap-3">
    <div>
      <button
        type="button"
        :class="{
          btn: true,
          'float-start': true,
          'btn-link': true,
          disabled: !myStore.currentUser.isVIP,
        }"
        @click="handleCreate"
      >
        新建<font-awesome-icon :icon="faAngleDoubleRight" />
      </button>
      <a class="float-end" href="#" ref="shareRef"
        ><font-awesome-icon :icon="faShare" />分享</a
      >
    </div>
    <HttpProxy ref="httpProxyRef" />
    <div
      v-if="needLogin"
      class="alert alert-secondary text-center"
      role="alert"
    >
      需要登录才能查看内容&nbsp;&nbsp;&nbsp;&nbsp;<a
        href="#"
        @click="handleLogin"
        >去登录<font-awesome-icon :icon="faAngleDoubleRight"
      /></a>
    </div>
    <div class="input-group" v-if="Object.keys(records).length > 0">
      <select class="form-select" @change="handleSelectCode" v-model="selCode">
        <option v-for="(value, key) in records" :key="key" :value="key">
          {{ value }}
        </option>
      </select>
      <button
        class="btn btn-outline-danger"
        type="button"
        v-if="planEntity.is_owns"
        :disabled="!planEntity.is_owns"
        @click="handleDelete"
      >
        删除
      </button>
      <button
        class="btn btn-primary"
        type="button"
        @click="handleSubmit"
        :disabled="!myStore.currentUser.isVIP"
      >
        保存
      </button>
    </div>
    <div class="audio-list row">
      <template v-if="audioArray.length > 0">
        <ol class="list-group list-group-numbered">
          <div v-for="(item, index) in audioArray" :key="index">
            <li class="list-group-item">
              <AudioPlayer
                :index="index"
                :file="item.file"
                :startTime="item.startTime"
                :endTime="item.endTime"
                :can-zh="false"
                @add-audio="addAudio"
                @merge-audio="mergeAudio"
              />
              <div class="title">
                <strong
                  v-if="item.title.trim().length && index != currTitle"
                  @click="toggleTitle(index)"
                  >{{ item.title }}</strong
                >
                <input
                  v-else
                  type="text"
                  v-model="item.title"
                  @click="toggleTitle(index)"
                  @blur="lostTitle(index)"
                />
                <strong
                  v-if="item.subtitle.trim().length && index != currSubtitle"
                  @click="toggleSubtitle(index)"
                  >{{ item.subtitle }}</strong
                >
                <input
                  v-else
                  type="text"
                  v-model="item.subtitle"
                  @click="toggleSubtitle(index)"
                  @blur="lostSubtitle(index)"
                />
              </div>
            </li>
          </div>
        </ol>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, onBeforeUnmount, computed } from "vue";
import { useRouter } from "vue-router";
import { userStore } from "@/stores/user";
import HttpProxy from "@/components/HttpProxy.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faAngleDoubleRight, faShare } from "@fortawesome/free-solid-svg-icons";
import AudioPlayer from "../components/AudioPlayer.vue";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";

const myStore = userStore();
const httpProxyRef = ref(null);
const fileSrc = ref("");
const audioArray = ref([]);
const currTitle = ref(-1);
const currSubtitle = ref(-1);
const router = useRouter();
const records = ref({});
const selCode = ref("");
const planEntity = ref({});
const needLogin = ref(false);
const shareRef = ref(null);
const maxLength = ref(10);

// 计算属性用于在模板中更方便地访问数组长度
const audioArrayLength = computed(() => audioArray.value.length);

const getAudioArray = async (code) => {
  if (httpProxyRef.value && httpProxyRef.value.getOwnPlans) {
    const response = await httpProxyRef.value.getOwnPlans(code);
    //console.log(response);
    try {
      if (response.status === 200) {
        try {
          audioArray.value = JSON.parse(response.data.data.plan.record);
          var fileURI = response.data.data.plan.file_link;
          if (fileURI) {
            audioArray.value.forEach((item) => {
              item.file = fileURI;
            });
          }
        } catch (error) {
          console.error(error);
        }
        planEntity.value = response.data.data.plan;
        selCode.value = response.data.data.plan.code; //选中的code
        records.value = response.data.data.record; //下拉列表

        const origin = window.location.origin;
        const fullUrl = new URL("/share", origin);
        const params = new URLSearchParams({ code: planEntity.value.code });
        fullUrl.search = params.toString();
        // 在组件挂载后初始化 Tippy
        shareRef.value._tippy = tippy(shareRef.value, {
          theme: "light-border",
          interactive: true,
          placement: "top",
          content: fullUrl.href,
          trigger: "click",
          maxWidth: "none",
        });
      } else {
        console.error("未知错误");
      }
    } catch (error) {
      console.error(error);
    }
  }
};

onMounted(async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  await getAudioArray(code || "");
});

onUnmounted(() => {
  // 销毁 tippy 实例
  if (shareRef.value && shareRef.value._tippy) {
    shareRef.value._tippy.destroy();
  }
});

const handleSelectCode = async () => {
  router.push({ path: "/record", query: { code: selCode.value } });
  await getAudioArray(selCode.value);
};

const handleDelete = async () => {
  if (!planEntity.value.is_owns) {
    return;
  }
  if (!confirm("确认删除这条记录吗？")) {
    return;
  }
  if (httpProxyRef.value && httpProxyRef.value.deletePlan) {
    const response = await httpProxyRef.value.deletePlan(planEntity.value._id);
    //console.log(response);
    try {
      if (response.status === 200) {
        planEntity.value.is_owns = false;
        audioArray.value = [];
      } else {
        console.error("未知错误");
      }
    } catch (error) {
      console.error(error);
    }
  }
};

const handleLogin = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  if (code) {
    router.push({ path: "/login", query: { code: code } });
    return;
  }
  router.push({ path: "/login" });
};

const handleCreate = () => {
  if (!myStore.currentUser.isVIP) {
    alert("你还不是会员");
    return;
  }
  router.push({ path: "/create" });
};

const handleSubmit = async () => {
  if (!planEntity.value.is_owns) {
    //不是我的询问
    if (httpProxyRef.value && httpProxyRef.value.existPlan) {
      const response = await httpProxyRef.value.existPlan(
        planEntity.value.file_hash
      );
      //console.log(response);
      try {
        if (response.status === 200) {
          if (
            response.data.data.IsExist &&
            !confirm("已经有这个文件的记录，要覆盖原来的记录吗？")
          ) {
            return;
          }
        } else {
          console.error("未知错误");
          return;
        }
      } catch (error) {
        console.error(error);
        return;
      }
    }
  }

  const tempAudioArray = structuredClone(audioArray.value);
  tempAudioArray.forEach((item) => {
    item.file = null;
  });

  planEntity.value.record = JSON.stringify(tempAudioArray);
  planEntity.value.is_public = false;

  if (httpProxyRef.value && httpProxyRef.value.createPlan) {
    //console.log("创建请求");
    const response = await httpProxyRef.value.createPlan(planEntity.value);
    //console.log(response);
    try {
      if (response.status != 201 && response.status != 200) {
        console.error("未知错误");
      }
    } catch (error) {
      console.error(error);
    }
  }
};

// 在组件卸载之前清理音频
onBeforeUnmount(() => {
  const audio = new Audio(fileSrc.value);
  audio.pause();
  audio.src = "";
});

const toggleTitle = (index) => {
  currTitle.value = index;
  currSubtitle.value = -1;
};

const toggleSubtitle = (index) => {
  currSubtitle.value = index;
  currTitle.value = -1;
};

const lostTitle = (index) => {
  if (currTitle.value === index) {
    currTitle.value = -1;
  }
};

const lostSubtitle = (index) => {
  if (currSubtitle.value === index) {
    currSubtitle.value = -1;
  }
};

const addAudio = (val) => {
  if (!myStore.currentUser.isVIP && audioArrayLength.value > maxLength.value) {
    if (confirm("去开通会员")) {
      router.push({ path: "/topUp" });
    }
    return;
  }
  const prevObj = audioArray.value[val.index];
  const newObj = {
    file: prevObj.file,
    startTime: val.startTime,
    endTime: prevObj.endTime,
    title: "",
    subtitle: "",
  };
  prevObj.endTime = val.startTime;
  audioArray.value.splice(val.index + 1, 0, newObj);
};

const mergeAudio = (val) => {
  if (val.index === 0) {
    return;
  }
  const prevObj = audioArray.value[val.index - 1];
  const currObj = audioArray.value[val.index];
  prevObj.endTime = currObj.endTime;
  prevObj.title += " " + currObj.title;
  prevObj.subtitle += " " + currObj.subtitle;
  audioArray.value.splice(val.index, 1);
};
</script>

<style scoped>
.audio-list {
  display: flex;
  flex-direction: column;
  align-items: center; /* 垂直居中 */
}

.title {
  display: flex;
  flex-direction: column;
}
</style>
