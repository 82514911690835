<template>
  <div class="audio-player bg-primary">
    <button
      class="base-button text-primary"
      ref="codeRef"
      data-tag="拆分"
      @click="addAudio"
    >
      <font-awesome-icon :icon="faCode" />
    </button>
    <button
      @click="togglePlay"
      ref="playRef"
      data-tag="播放/暂停"
      class="base-button text-primary"
    >
      <font-awesome-icon :icon="isPlaying ? faPause : faPlay" />
    </button>
    <input
      type="range"
      v-bind:min="minValue"
      :max="duration"
      v-model="currentTime"
      class="form-range progress-bar"
    />
    <span class="time">{{ formatTime(currentTime) }}</span> /
    <span class="time">{{ formatTime(duration) }}</span>
    <button
      class="base-button text-primary"
      ref="upRef"
      data-tag="向上合并"
      @click="mergeAudio"
    >
      <font-awesome-icon :icon="faAngleDoubleUp" />
    </button>
    <button
      v-if="canZh"
      class="base-button text-primary"
      ref="textRef"
      data-tag="转文字(仅支持英文)"
      @click="toText"
    >
      文
    </button>
  </div>
</template>

<script setup>
import {
  defineEmits,
  defineProps,
  ref,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faPlay,
  faPause,
  faCode,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";

const codeRef = ref(null);
const playRef = ref(null);
const upRef = ref(null);
const textRef = ref(null);

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  file: {
    type: String,
    required: true,
  },
  startTime: {
    type: Number,
    default: 0,
  },
  endTime: {
    type: Number,
    default: 0,
  },
  canZh:{
    type: Boolean,
    default: false
  }
});

// 监听传入参数变化
watch(
  () => [props.file, props.startTime, props.endTime],
  () => {
    audio.value.src = props.file;
    duration.value =
      props.endTime > audio.value.duration
        ? audio.value.duration
        : props.endTime;
    audio.value.currentTime = props.startTime < 0 ? 0 : props.startTime;
  }
);

// 声明 emit 函数
const emit = defineEmits(["add-audio", "merge-audio"]);

const audio = ref(new Audio());
const isPlaying = ref(false);
const currentTime = ref(0);
const lastTime = ref(0);
const minValue = ref(0);
const duration = ref(0);

// 格式化时间
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

// 播放/暂停音频
const togglePlay = () => {
  if (isPlaying.value) {
    audio.value.pause();
  } else {
    audio.value.currentTime = currentTime.value;
    audio.value.play();
  }
  isPlaying.value = !isPlaying.value;
};

const addAudio = () => {
  if (isPlaying.value) {
    audio.value.pause();
    isPlaying.value = !isPlaying.value;
  }
  const emitObj = { index: props.index, startTime: currentTime.value };
  emit("add-audio", emitObj);
};

const mergeAudio = () => {
  if (isPlaying.value) {
    audio.value.pause();
    isPlaying.value = !isPlaying.value;
  }
  const emitObj = { index: props.index };
  emit("merge-audio", emitObj);
};

const toText = () => {
  const emitObj = { index: props.index};
  emit("to-text", emitObj);
};

// 更新播放时间
const updateTime = () => {
  //console.log(event);
  if (currentTime.value >= props.endTime) {
    audio.value.currentTime = props.startTime;
    currentTime.value = audio.value.currentTime;
  } else if (currentTime.value != lastTime.value) {
    audio.value.currentTime = currentTime.value;
  } else {
    currentTime.value = audio.value.currentTime;
  }
  lastTime.value = currentTime.value;
};

// 处理音频结束事件
const handleEnded = () => {
  currentTime.value = props.startTime || 0;
  isPlaying.value = true;
  audio.value.play();
};

// 监听音频元数据加载完成
onMounted(() => {
  audio.value.src = props.file;
  audio.value.addEventListener("loadedmetadata", () => {
    minValue.value = props.startTime < 0 ? 0 : props.startTime;
    duration.value =
      props.endTime > audio.value.duration
        ? audio.value.duration
        : props.endTime;
    audio.value.currentTime = props.startTime || 0;
  });
  audio.value.addEventListener("timeupdate", updateTime);
  audio.value.addEventListener("ended", handleEnded);

  // 初始化 tippy 实例
  const codeTip = tippy(codeRef.value, {
    content: codeRef.value.dataset.tag,
    theme: "light-border",
    placement: "top",
    arrow: true,
  });

  const playTip = tippy(playRef.value, {
    content: playRef.value.dataset.tag,
    theme: "light-border",
    placement: "top",
    arrow: true,
  });

  const upTip = tippy(upRef.value, {
    content: upRef.value.dataset.tag,
    theme: "light-border",
    placement: "top",
    arrow: true,
  });

  // 存储 tippy 实例以便后续销毁
  codeRef.value._tippy = codeTip;
  playRef.value._tippy = playTip;
  upRef.value._tippy = upTip;

  if (props.canZh) {
    const textTip = tippy(textRef.value, {
      content: textRef.value.dataset.tag,
      theme: "light-border",
      placement: "top",
      arrow: true,
    });
    textRef.value._tippy = textTip;
  }
});

// 组件卸载时移除事件监听
onUnmounted(() => {
  audio.value.removeEventListener("timeupdate", updateTime);
  audio.value.removeEventListener("ended", handleEnded);
  audio.value.src = null;

  // 销毁 tippy 实例
  if (codeRef.value && codeRef.value._tippy) {
    codeRef.value._tippy.destroy();
  }
  if (playRef.value && codeRef.value._tippy) {
    codeRef.value._tippy.destroy();
  }
  if (upRef.value && upRef.value._tippy) {
    upRef.value._tippy.destroy();
  }
  if (textRef.value && textRef.value._tippy) {
    textRef.value._tippy.destroy();
  }
});
</script>

<style scoped>
.audio-player {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  color: white;
}

.base-button {
  /* 按钮样式 */
  background-color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  margin: 6px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  margin: 0 10px;
  width: 100%;
}

.progress-bar::-webkit-slider-thumb {
  border: 2px solid white;
}

.time {
  font-size: 12px;
}
</style>
