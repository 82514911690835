<template>
  <div class="text-center">
    <figure class="figure">
      <img
        src="img/adv/gaaguo.png"
        class="figure-img img-fluid rounded"
        alt="..."
      />
      <figcaption class="figure-caption">
        遇到问题请微信联系：{{ myStore.wechat }}
      </figcaption>
    </figure>
  </div>
</template>

<script setup>
import { userStore } from "@/stores/user";
const myStore = userStore();
</script>

<style scoped></style>
