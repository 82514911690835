<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <!-- <div class="vr mx-3 bg-light-subtle"></div> -->
      <router-link class="navbar-brand logo" to="/"
        ><img src="logo.png" style="width: 30px; height: 30px" alt="logo" />{{
          myStore.app
        }}</router-link
      >
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul v-if="navs?.length > 0" class="navbar-nav">
          <template v-for="item in navs" :key="item.index">
            <li v-if="item?.children?.length === 0" class="nav-item">
              <router-link class="nav-link" :to="item.url"
                >{{ item.name
                }}<span
                  v-if="item.news && myStore.newOrderCount > 0"
                  class="badge rounded-pill text-bg-danger"
                  >{{ myStore.newOrderCount }}</span
                ></router-link
              >
            </li>
            <li v-else class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ item.name }}
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <template v-for="child in item.children" :key="child.index">
                  <li>
                    <router-link class="dropdown-item" :to="child.url">{{
                      child.name
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </li>
          </template>
        </ul>
        <!--右边-->
        <ul class="navbar-nav ms-auto">
          <li class="nav-item vip-text" ref="vipTitle">
            <router-link
              class="nav-link"
              to="/topUp"
              v-if="myStore.currentUser.name"
              ><span
                :class="{
                  badge: true,
                  'text-bg-warning': myStore.currentUser.isVIP,
                  'text-bg-secondary': !myStore.currentUser.isVIP,
                }"
                >VIP{{ myStore.currentUser.vipLevel }}</span
              ></router-link
            >
          </li>
          <li v-if="myStore.currentUser.name" class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ myStore.currentUser.name }}
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <li>
                <router-link class="dropdown-item" to="/myorder"
                  ><font-awesome-icon
                    :icon="faClipboard"
                  />&ensp;我的订单</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/setPwd"
                  ><font-awesome-icon
                    :icon="faEdit"
                  />&ensp;修改密码</router-link
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click="handleLogout"
                  ><font-awesome-icon :icon="faSignOut" />&ensp;退出</a
                >
              </li>
            </ul>
          </li>
          <li v-else class="nav-item dropdown">
            <a class="nav-link" href="/login"> 登录 </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch, computed, defineProps } from "vue";
import { userStore } from "@/stores/user";
import { useRouter } from "vue-router";
import { newOrderURL } from "@/script/axios.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faEdit,
  faSignOut,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/translucent.css";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
});

watch(
  () => props.data,
  (newValue) => {
    navs.value = newValue;
    openSSE();
  },
  { deep: false }
);

const expireDay = computed(() => {
  // 计算两个日期之间的时间差（以毫秒为单位）  
  const timeDifference = myStore.currentUser.expireDate - Date.now()/1000;  

  // 计算相差的天数  
  const daysDifference = Math.floor(timeDifference / (60 * 60 * 24)); 
  return daysDifference < 0 ? 0 : daysDifference;
});

const router = useRouter();
const myStore = userStore();
const navs = ref([]);
const vipTitle = ref(null);
var eventNewOrder = null;

const handleLogout = () => {
  closeSSE();
  navs.value = [];
  myStore.cleanCurrentUser();
  router.push("/login");
};

const openSSE = () => {
  if (eventNewOrder && eventNewOrder.readyState === EventSource.OPEN) {
    return;
  }
  eventNewOrder = new EventSource(newOrderURL + "/" + myStore.currentUser.id);
  eventNewOrder.onmessage = (event) => {
    //console.log("收到");
    //console.log(event.data);
    const intValue = parseInt(event.data, 10);

    // 检查数据是否是有效的数字
    if (!isNaN(intValue) && Number.isInteger(intValue)) {
      myStore.addNewOrderCount(intValue);
    }
  };

  eventNewOrder.onerror = (err) => {
    console.error("EventNewOrder failed:", err);
    eventNewOrder.close();
  };

  eventNewOrder.addEventListener("close", (event) => {
    console.log("SSE 连接已关闭，原因：", event.code);
  });
};

const closeSSE = () => {
  if (!eventNewOrder) {
    return;
  }
  if (eventNewOrder.readyState === EventSource.OPEN) {
    eventNewOrder.close();
  }
};

onMounted(() => {
  // 初始化 tippy 实例
  if (myStore.currentUser.id) {
    navs.value = myStore.currentUser.navs;
  }
  const tooltip = tippy(vipTitle.value, {
    //content: '',
    theme: "translucent",
    placement: "bottom",
    arrow: true,
    delay: [100, 0],
    duration: [200, 100],
    onShow(instance) {
       instance.setContent(`剩余${expireDay.value}天`);
    },
  });

  // 存储 tippy 实例以便后续销毁
  vipTitle.value._tippy = tooltip;
});

onUnmounted(() => {
  // 销毁 tippy 实例
  if (vipTitle.value && vipTitle.value._tippy) {
    vipTitle.value._tippy.destroy();
  }
});
</script>
<style scoped>
.logo {
  font-family: STHupo;
  font-size: x-large;
  font-style: italic;
}
</style>
