<template>
  <div class="container">
    <div v-show="myStore.isAdmin()">
      <HttpProxy ref="httpProxyRef0" />
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar-example2"
        data-bs-root-margin="0px 0px -40%"
        data-bs-smooth-scroll="true"
        class="scrollspy-example bg-body-tertiary p-3 rounded-2"
        tabindex="0"
      >
        <div class="bg-body-tertiary" v-if="siteInforms.length > 0">
          <template v-for="(item, index) in siteInforms" :key="index">
            <HttpProxy :ref="getRef(index)" />
            <SiteInfo
              :title="item.title"
              :textJson="item.textJson"
              :next-handle="item.nextHandle"
              :index="index"
              @handleApply="setSiteInfo"
              @handleSave="saveSiteInfo"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="bg-secondary-subtle board">
      <h1>成为会员</h1>
      <p>
        成为会员可以享受最大50兆的音频上传权益，同步保存记录方便下次继续操作，不限制拆分音频，共享资料，AI翻译，音频转文本等。
      </p>
    </div>
    <div class="container text-center">
      <br />
      <div class="row">
        <template v-for="(item, index) in payTypes" :key="index">
          <div class="col-sm-4">
            <div class="card" style="width: 18rem">
              <div class="card-header">
                <strong>{{ item.Title }}</strong>
              </div>
              <div class="card-body">
                <ul v-for="li in item.Clause.split(';')" :key="li">
                  <li>{{ li }}</li>
                </ul>
                <br />
                <template v-if="item.Price > 0">
                  <h3>
                    <strong>￥{{ item.Price }}</strong>
                  </h3>
                </template>
                <a
                  :class="{
                    btn: true,
                    'position-relative': true,
                    'btn-secondary': item.Price <= 0,
                    'btn-outline-primary': item.Price > 0,
                  }"
                  @click="handleBuy(item)"
                  :data-bs-toggle="item.Price > 0 ? 'offcanvas' : ''"
                  :data-bs-target="item.Price > 0 ? '#offcanvasRight' : ''"
                  :aria-controls="item.Price > 0 ? 'offcanvasRight' : ''"
                  >购买<span
                    v-if="item.SubTitle"
                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"
                    >{{ item.SubTitle }}</span
                  ></a
                >
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasRightLabel">商品信息</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <form class="row g-3" @submit.prevent="handleSubmit">
        <div class="input-group mb-3 vstack gap-3 text-center">
          <h2 class="text-start">{{ orderPay.title }}</h2>
          <ul v-if="orderPay.clause.length > 0" class="text-start">
            <template v-for="item in orderPay.clause.split(';')" :key="item">
              <li>{{ item }}</li>
            </template>
          </ul>
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <template v-for="(item, index) in orderPay.payQueue" :key="index">
                <h2
                  class="accordion-header"
                  :id="'flush-headingOne' + item.value"
                >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#flush-heading' + item.value"
                    aria-expanded="false"
                    :aria-controls="'flush-heading' + item.value"
                    @click="setPayWay(item.value)"
                  >
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        :id="'exampleRadios+' + item.value"
                        :value="item.value"
                        v-model="orderPay.payWay"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#flush-heading' + item.value"
                        aria-expanded="false"
                        :aria-controls="'flush-heading' + item.value"
                      />
                      <label
                        class="form-check-label"
                        :for="'exampleRadios' + item.value"
                      >
                        {{ item.name }}
                      </label>
                    </div>
                  </button>
                </h2>
                <div
                  :id="'flush-heading' + item.value"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'flush-headingOne' + item.value"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <img :src="item.img" class="img-fluid" :alt="item.alt" />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <p v-if="checkPayWay" class="text-danger text-start">
            {{ checkPayWay }}
          </p>
          <div class="form-text text-start">
            *支付完成后打开微信或支付宝账单里找到付款的订单，输入订单号后8位数
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text is-invalid">XXXXXXXXXXXXXXXX</span>
            <input
              type="text"
              :class="{
                'form-control': true,
                'is-invalid': checkRemarkNum,
              }"
              placeholder="订单号后8位数"
              v-model="orderPay.remarkNum"
              @input="limitLength"
              required
            />
            <!-- <select class="form-select is-invalid" v-model="orderPay.payWay">
            <option value="-1" selected>支付方式</option>
            <option
              v-for="(item, index) in payWay"
              :key="index"
              :value="item.value"
            >
              {{ item.subName }}
            </option>
          </select> -->
            <div class="invalid-feedback">{{ checkRemarkNum }}</div>
          </div>

          <div class="input-group mb-3">
            <span class="input-group-text">￥</span>
            <input
              type="number"
              step="0.01"
              class="form-control"
              v-model="orderPay.price"
              readonly="isReadOnly"
            />
          </div>

          <div class="input-group mb-3">
            <span class="input-group-text">备注(选填)</span>
            <div>
              <textarea
                class="form-control"
                v-model="orderPay.remarkText"
              ></textarea>
            </div>
          </div>
          <HttpProxy ref="httpProxyRef" />
          <div class="col-12">
            <button class="btn btn-primary" type="submit">提交</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, reactive, toRefs, ref } from "vue";
import { useRouter } from "vue-router";
import { userStore } from "@/stores/user";
import HttpProxy from "@/components/HttpProxy.vue";
import SiteInfo from "@/components/SiteInfo.vue";

const myStore = userStore();
const router = useRouter();
const httpProxyRef0 = ref(null);
const httpProxyRef = ref(null);
const siteInforms = ref([]);
const payWay = ref([]);
const payTypes = ref([]);
const httpProxyRefs = reactive({});
const orderPay = reactive({
  remarkNum: "",
  payWay: -1,
  remarkText: "",
  title: "",
  price: 0,
  type: 0,
  clause: [],
  payQueue: [],
});
const topUpOrderTypeNode = ref({
  title: "支付——购买套餐",
  textJson: "",
  nextHandle: "handleTopUpOrderType",
});

const topUpPayWayNode = ref({
  title: "支付——支付方式",
  textJson: "",
  nextHandle: "handleTopUpPayWay",
});

const setSiteInfo = (data) => {
  //console.log(data);
  if (!data.nextHandle) {
    alert("没有找到下一个处理函数");
    return;
  }
  switch (data.nextHandle) {
    case "handleTopUpPayWay":
      setOrderTopUpPayWay(data.value);
      break;
    case "handleTopUpOrderType":
      setOrderTopUpOrderType(data.value);
      break;
    default:
      break;
  }
};

const saveSiteInfo = (data) => {
  if (!data.nextHandle) {
    alert("请求错误");
    return;
  }
  switch (data.nextHandle) {
    case "handleTopUpPayWay":
      saveOrderTopUpPayWay(data.value, data.index);
      break;
    case "handleTopUpOrderType":
      saveOrderTopUpOrderType(data.value, data.index);
      break;
    default:
      break;
  }
};

const setOrderTopUpPayWay = (val) => {
  payWay.value = JSON.parse(val);
};

const setOrderTopUpOrderType = (val) => {
  payTypes.value = JSON.parse(val);
};

const handleBuy = (payType) => {
  orderPay.title = payType.Title;
  orderPay.price = payType.Price;
  orderPay.type = payType.OrderType;
  orderPay.clause = payType.SubClause;
  orderPay.payQueue = payWay.value.filter(
    (item) => item.type === payType.OrderType
  );
};

const setPayWay = (val) => {
  orderPay.payWay = val;
  checkPayWay.value = "";
};

const checkRemarkNum = ref("");

const checkPayWay = ref("");

const limitLength = (event) => {
  const input = event.target;
  orderPay.remarkNum = input.value.substring(0, 8); // 限制长度为 8
};

const saveOrderTopUpPayWay = async (val, index) => {
  if (httpProxyRefs[index] && httpProxyRefs[index].updateTopUpPayWay) {
    const data = {
      text: val,
    };
    const response = await httpProxyRefs[index].updateTopUpPayWay(data);
    //console.log(response);
    try {
      if (response.status != 201 && response.status != 200) {
        console.error("未知错误");
      }
    } catch (error) {
      console.error(error);
    }
  }
};

const saveOrderTopUpOrderType = async (val, index) => {
  if (httpProxyRefs[index] && httpProxyRefs[index].updateTopUpOrderType) {
    const data = {
      text: val,
    };
    const response = await httpProxyRefs[index].updateTopUpOrderType(data);
    //console.log(response);
    try {
      if (response.status != 201 && response.status != 200) {
        console.error("未知错误");
      }
    } catch (error) {
      console.error(error);
    }
  }
};

const getAllNodes = async (row) => {
  var node;
  //console.log(row);
  if (httpProxyRef0.value && httpProxyRef0.value.getSiteNodes) {
    const data = ["top_up_order_type", "top_up_pay_way"];
    const response = await httpProxyRef0.value.getSiteNodes(row, data);
    //console.log(response);
    try {
      if (response.status != 200) {
        console.error("未知错误");
      }
      node = response.data.data.data;
    } catch (error) {
      console.error(error);
    }
  }

  if (node) {
    topUpOrderTypeNode.value.textJson = node.top_up_order_type || "";
    topUpPayWayNode.value.textJson = node.top_up_pay_way || "";
    if (node.top_up_order_type) {
      payTypes.value = JSON.parse(node.top_up_order_type);
    }
    if (node.top_up_pay_way) {
      payWay.value = JSON.parse(node.top_up_pay_way);
    }
  }
};

const handleSubmit = async () => {
  //console.log(orderPay);
  checkPayWay.value = checkRemarkNum.value = "";
  if (orderPay.remarkNum.length != 8) {
    checkRemarkNum.value = "请输入支付订单的后8位数字";
    return;
  }
  if (orderPay.payWay == -1) {
    checkPayWay.value = "请选择支付方式";
    return;
  }
  const data = {
    remark_num: orderPay.remarkNum,
    pay_way: orderPay.payWay,
    price: orderPay.price,
    remark_text: orderPay.remarkText,
    order_type: orderPay.type,
  };
  //console.log(data);
  if (httpProxyRef.value && httpProxyRef.value.createOrder) {
    const response = await httpProxyRef.value.createOrder(data);
    //console.log(response);
    try {
      if (response.status === 201) {
        myStore.currentUser.isVIP = true;
        if (myStore.currentUser.permissionLevel < myStore.vip) {
          myStore.currentUser.permissionLevel = myStore.vip;
        }
        alert(response.data.message);
        router.push("/");
      } else {
        console.error("未知错误");
      }
    } catch (error) {
      console.error(error);
    }
  }
};

const getRef = (index) => {
  return (el) => {
    httpProxyRefs[index] = toRefs(el);
  };
};

onMounted(async () => {
  // const payTypeJson =
  //   '[{"Title":"非会员","SubTitle":"","Price":0,"OrderType":-1,"Clause":"支持上传音频文件最大20兆;拆分音频上限10次;无法保存操作记录","SubClause":""},\
  //   {"Title":"半年","SubTitle":"+1个月","Price":108,"OrderType":7,"Clause":"支持上传音频文件最大50兆;不限音频拆分次数;同步保存操作记录;送一个月会员","SubClause":"加送1个月"},\
  //   {"Title":"包月","SubTitle":"","Price":18,"OrderType":1,"Clause":"支持上传音频文件最大50兆;不限音频拆分次数;同步保存操作记录","SubClause":""}]';
  // payTypes.value = JSON.parse(payTypeJson);
  // const payWayJson =
  //   '[{"type":1,"name":"微信支付","subName":"微信","img":"wx_pay.png","value":1,"alt":"微信付款"},{"type":2,"name":"支付宝支付","subName":"支付宝","img":"zfb_pay.png","value":2,"alt":"支付宝付款"}]';

  // payWay.value = JSON.parse(payWayJson);
  siteInforms.value.push(topUpOrderTypeNode.value);
  siteInforms.value.push(topUpPayWayNode.value);
  await getAllNodes(0);
});

onUnmounted(() => {});
</script>

<style scoped>
.board {
  padding: 60px;
}
</style>
