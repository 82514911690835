<template>
  <AlertMessage :data="alertMsg" />
  <div
    v-if="uploadProgress > 0"
    class="progress"
    role="progressbar"
    aria-label="Animated striped example"
    :aria-valuenow="uploadProgress"
    aria-valuemin="0"
    aria-valuemax="100"
  >
    <div
      class="progress-bar progress-bar-striped progress-bar-animated"
      :style="{ width: uploadProgress + '%' }"
    >
      {{ uploadProgress }}%
    </div>
  </div>
  <div
    :class="{ modal: true, fade: true, show: isLoadVisible }"
    :style="{ display: isLoadVisible ? 'block' : 'none' }"
    tabindex="-1"
  >
    <div class="overlay">
      <div class="rectangle">
        <div class="shape1"></div>
        <div class="shape2"></div>
        <div class="shape3"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineExpose, ref, reactive } from "vue";
import AlertMessage from "@/components/AlertMessage.vue";
import router from "@/router"; // 导入路由实例
import { userStore } from "@/stores/user";
import {
  axiosGoTest,
  axiosSendEmail,
  axiosGetEmail,
  axiosExistUser,
  axiosRegister,
  axiosLogin,
  axiosResetPassword,
  axiosSetPassword,
  axiosCreateOrder,
  axiosGetOwnOrders,
  axiosGetAllOrders,
  axiosGetAllUsers,
  axiosSSETest,
  axiosSetUserEnabled,
  axiosSetOrdersEnabled,
  axiosUploadFile,
  axiosExistPlan,
  axiosCreatePlan,
  axiosGetPlan,
  axiosGetOwnPlans,
  axiosDeletePlan,
  axiosIsPublicPlan,
  axiosGetPartPlans,
  axiosGetSiteNodes,
  axiosUpdateNavigation,
  axiosUpdateAdvertising,
  axiosUpdateOrderPayWay,
  axiosUpdateOrderOrderType,
  axiosUpdateOrderOrderStatus,
  axiosUpdateTopUpOrderType,
  axiosUpdateTopUpPayWay,
  axiosSpeechToText,
  //axiosGetNavigation,
} from "@/script/axios.js";

const myStore = userStore();
const uploadProgress = ref(0);
const isLoadVisible = ref(false);
const alertMsg = reactive({
  type: 0,
  content: "",
  autoHide: false,
  time: Date.now(),
});

const myAlert = (code = 0, msg, autoHide = false) => {
  alertMsg.code = code;
  alertMsg.message = msg;
  alertMsg.autoHide = autoHide;
  alertMsg.timeRange = Date.now();
};

const httpTest = async (request) => {
  return await axiosGoTest(request);
};

const sseTest = async () => {
  return await axiosSSETest();
};

const sendEmail = async (data) => {
  isLoadVisible.value = true;
  return await axiosSendEmail(data)
    .then((response) => {
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("SendEmail error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};
const getEmail = async (email, url) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosGetEmail(email, url)
    .then((response) => {
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("GetEmail error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const existUser = async (data) => {
  isLoadVisible.value = true;
  return await axiosExistUser(data)
    .then((response) => {
      //console.log(response);
      return response;
    })
    .catch((error) => {
      //console.log("ExistUser error: ");
      console.error("ExistUser error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      //console.log("finally");
      isLoadVisible.value = false;
    });
};

const register = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (token) {
    alert("你已经登录");
    return;
  }
  isLoadVisible.value = true;
  return await axiosRegister(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      return response;
    })
    .catch((error) => {
      console.error("Register error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const login = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (token) {
    alert("你已经登录");
    return;
  }
  isLoadVisible.value = true;
  return await axiosLogin(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      return response;
    })
    .catch((error) => {
      console.error("Login error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const setUserEnabled = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosSetUserEnabled(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      return response;
    })
    .catch((error) => {
      console.error("SetUserEnabled error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const resetPassword = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (token) {
    alert("你已经登录");
    return;
  }
  isLoadVisible.value = true;
  return await axiosResetPassword(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      return response;
    })
    .catch((error) => {
      console.error("ResetPassword error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const setPassword = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosSetPassword(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      return response;
    })
    .catch((error) => {
      console.error("SetPassword error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const createOrder = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosCreateOrder(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      return response;
    })
    .catch((error) => {
      console.error("CreateOrder error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const getOwnOrders = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosGetOwnOrders(data)
    .then((response) => {
      if (response.status != 200) {
        myAlert(response.status, response.data.message, false);
      }
      return response;
    })
    .catch((error) => {
      console.error("GetOwnOrders error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const getAllOrders = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosGetAllOrders(data)
    .then((response) => {
      if (response.status != 200) {
        myAlert(response.status, response.data.message, false);
      }
      return response;
    })
    .catch((error) => {
      console.error("GetAllOrders error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const getAllUsers = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosGetAllUsers(data)
    .then((response) => {
      if (response.status != 200) {
        myAlert(response.status, response.data.message, false);
      }
      return response;
    })
    .catch((error) => {
      console.error("GetAllUsers error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const setOrdersEnabled = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosSetOrdersEnabled(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      return response;
    })
    .catch((error) => {
      console.error("SetOrdersEnabled error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const config = {
  onUploadProgress: (progressEvent) => {
    // 计算进度百分比
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    uploadProgress.value = percentCompleted;
  },
};

const uploadFile = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }

  return await axiosUploadFile(data, config)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      return response;
    })
    .catch((error) => {
      console.error("UploadFile error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      uploadProgress.value = 0;
    });
};

const existPlan = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosExistPlan(data)
    .then((response) => {
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("ExistPlan error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const createPlan = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosCreatePlan(data)
    .then((response) => {
      //console.log(response);
      myAlert(response.status, response.data.message, true);
      return response;
    })
    .catch((error) => {
      console.error("CreatePlan error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const getPlan = async (data) => {
  isLoadVisible.value = true;
  const token = localStorage.getItem(myStore.storage);
  return await axiosGetPlan(data, token)
    .then((response) => {
      //console.log(response);
      if (response.status != 200) {
        myAlert(response.status, response.data.message, true);
      }
      return response;
    })
    .catch((error) => {
      console.error("GetPlan error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const getOwnPlans = async (code) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosGetOwnPlans(code)
    .then((response) => {
      //console.log(response);
      if (response.status != 200) {
        myAlert(response.status, response.data.message, true);
      }
      return response;
    })
    .catch((error) => {
      console.error("GetOwnPlans error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const deletePlan = async (id) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosDeletePlan(id)
    .then((response) => {
      myAlert(response.status, response.data.message, false);
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("DeletePlan error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const isPublicPlan = async (code) => {
  isLoadVisible.value = true;
  return await axiosIsPublicPlan(code)
    .then((response) => {
      if (response.status != 200) {
        myAlert(response.status, response.data.message, false);
      }
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("IsPublicPlan error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const getPartPlans = async (code) => {
  isLoadVisible.value = true;
  return await axiosGetPartPlans(code)
    .then((response) => {
      if (response.status != 200) {
        myAlert(response.status, response.data.message, false);
      }
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("GetPartPlans error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const getSiteNodes = async (limit, data) => {
  isLoadVisible.value = true;
  return await axiosGetSiteNodes(limit, data)
    .then((response) => {
      if (response.status != 200) {
        myAlert(response.status, response.data.message, true);
      }
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("GetSiteNodes error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, true);
        return error.response;
      } else {
        myAlert(500, error.message, true);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const updateNavigation = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosUpdateNavigation(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("UpdateNavigation error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const updateAdvertising = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosUpdateAdvertising(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("UpdateAdvertising error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const updateOrderPayWay = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosUpdateOrderPayWay(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("UpdateOrderPayWay error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const updateOrderOrderType = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosUpdateOrderOrderType(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("UpdateOrderOrderType error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const updateOrderOrderStatus = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosUpdateOrderOrderStatus(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("UpdateOrderOrderStatus error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const updateTopUpOrderType = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosUpdateTopUpOrderType(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("UpdateTopUpOrderType error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const updateTopUpPayWay = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosUpdateTopUpPayWay(data)
    .then((response) => {
      myAlert(response.status, response.data.message, true);
      //console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("UpdateTopUpPayWay error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
};

const speechToText = async (data) => {
  const token = localStorage.getItem(myStore.storage);
  if (!token) {
    alert("登录超时，请重新登录");
    router.push("/login");
    return;
  }
  isLoadVisible.value = true;
  return await axiosSpeechToText(data)
    .then((response) => {
      if (response.status != 200) {
        myAlert(response.status, response.data.message, true);
      }
      return response;
    })
    .catch((error) => {
      console.error("SpeechToText error: ", error);
      if (error.response) {
        myAlert(error.response.status, error.response.data.message, false);
        return error.response;
      } else {
        myAlert(500, error.message, false);
      }
    })
    .finally(() => {
      isLoadVisible.value = false;
    });
}

// const getNavigation = async () => {
//   isLoadVisible.value = true;
//   return await axiosGetNavigation()
//     .then((response) => {
//       if (response.status != 200) {
//         myAlert(response.status, response.data.message, true);
//       }
//       //console.log(response);
//       return response;
//     })
//     .catch((error) => {
//       console.error("GetNavigation error: ", error);
//       if (error.response) {
//         myAlert(error.response.status, error.response.data.message, true);
//         return error.response;
//       } else {
//         myAlert(500, error.message, true);
//       }
//     })
//     .finally(() => {
//       isLoadVisible.value = false;
//     });
// };

// 暴露方法，以便父组件可以调用
defineExpose({
  httpTest,
  sseTest,
  sendEmail,
  getEmail,
  existUser,
  register,
  login,
  resetPassword,
  setPassword,
  createOrder,
  getOwnOrders,
  getAllOrders,
  getAllUsers,
  setUserEnabled,
  setOrdersEnabled,
  uploadFile,
  existPlan,
  createPlan,
  getPlan,
  getOwnPlans,
  deletePlan,
  isPublicPlan,
  getPartPlans,
  getSiteNodes,
  updateNavigation,
  updateAdvertising,
  updateOrderPayWay,
  updateOrderOrderType,
  updateOrderOrderStatus,
  updateTopUpOrderType,
  updateTopUpPayWay,
  //getNavigation,
  speechToText,
});
</script>
<style scoped>
[v-cloak] {
  display: none;
}

.rectangle {
  width: 200px;
  height: 200px;
  position: relative;
  margin: 0 auto;
}

@keyframes fadeInOut1 {
  0% {
    transform: translateY(-100px); /* 开始时向下移动100px */
    opacity: 0; /* 初始透明度为0 */
  }
  50% {
    transform: translateY(-80px); /* 开始时向下移动100px */
    opacity: 0; /* 初始透明度为0 */
  }
  70% {
    transform: translateY(0); /* 移动到原位置 */
    opacity: 1; /* 最终透明度为1 */
  }
  81% {
    transform: translateY(0); /* 移动到原位置 */
    opacity: 1; /* 最终透明度为1 */
  }
  100% {
    transform: translateY(100px); /* 移动到原位置 */
    opacity: 0; /* 最终透明度为1 */
  }
}

@keyframes fadeInOut2 {
  0% {
    transform: translateY(-100px); /* 开始时向下移动100px */
    opacity: 0; /* 初始透明度为0 */
  }
  30% {
    transform: translateY(-100px); /* 开始时向下移动100px */
    opacity: 0; /* 初始透明度为0 */
  }
  70% {
    transform: translateY(0); /* 移动到原位置 */
    opacity: 1; /* 最终透明度为1 */
  }
  78% {
    transform: translateY(0); /* 移动到原位置 */
    opacity: 1; /* 最终透明度为1 */
  }
  100% {
    transform: translateY(100px); /* 移动到原位置 */
    opacity: 0; /* 最终透明度为1 */
  }
}

@keyframes fadeInOut3 {
  0% {
    transform: translateY(-100px); /* 开始时向下移动100px */
    opacity: 0; /* 初始透明度为0 */
  }
  70% {
    transform: translateY(0); /* 移动到原位置 */
    opacity: 1; /* 最终透明度为1 */
  }
  100% {
    transform: translateY(100px); /* 移动到原位置 */
    opacity: 0; /* 最终透明度为1 */
  }
}

.shape1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #B3B3B3;
  clip-path: polygon(41% 11%, 53% 6%, 58% 21%, 36% 29%);
  animation: fadeInOut1 2s infinite; /* 2秒循环无限 */
}

.shape2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #B3B3B3;
  clip-path: polygon(34% 32%, 61% 22%, 67% 38%, 27% 54%);
  animation: fadeInOut2 2s infinite; /* 2秒循环无限 */
}

.shape3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #B3B3B3;
  clip-path: polygon(
    69% 40%,
    81% 72%,
    51% 72%,
    50% 63%,
    48% 63%,
    47% 72%,
    20% 72%,
    24% 58%
  );
  animation: fadeInOut3 2s infinite; /* 2秒循环无限 */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 105%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 确保遮罩层在最顶部 */
}
</style>
