<template>
  <div class="container text-center">
    <HttpProxy ref="httpProxyRef" />
    <div class="row row-cols-1 row-cols-md-2 g-4">
      <template v-for="(plan, index) in planArray" :key="index">
        <div class="col">
          <div class="card">
            <img :src="plan.image" class="card-img-top img-thumbnail" :alt="plan.image" />
            <div class="card-body">
              <h5 class="card-title">{{ plan.title }}</h5>
              <p class="card-text multi-line-clamp">
                {{ plan.subtitle }}
              </p>
              <a href="#" @click="handleShare(plan.code)" class="btn btn-primary">Play</a>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import HttpProxy from "@/components/HttpProxy.vue";

const router = useRouter();
const httpProxyRef = ref(null);
const planArray = ref([]);

const handleShare = (code) => {
  //console.log(code);
  router.push({ path: "/share", query: { code: code } });
};

onMounted(async () => {
  if (httpProxyRef.value && httpProxyRef.value.getPartPlans) {
    const response = await httpProxyRef.value.getPartPlans();
    //console.log(response);
    try {
      if (response.status === 200) {
        planArray.value = response.data.data.data;
      } else {
        console.error("未知错误");
      }
    } catch (error) {
      console.error(error);
    }
  }
});
</script>
<style scoped>
.multi-line-clamp {  
    display: -webkit-box;  
    -webkit-box-orient: vertical;  
    overflow: hidden;  
    -webkit-line-clamp: 5; /* 设置希望显示的行数 */  
}  
</style>
